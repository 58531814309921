import { makeObservable, observable, action} from 'mobx';
//import rx from 'feathers-reactive/dist/feathers-reactive';
import rx from 'feathers-reactive';
import io from 'socket.io-client'; 
import feathers from '@feathersjs/feathers';
//import feathersSocketIOClient from '@feathersjs/socketio-client';
import socketio from '@feathersjs/socketio-client';
import auth from '@feathersjs/authentication-client';
import * as cloneDeep from 'lodash/cloneDeep';


const API_URL = 'https://ffi.appdate.gr';
const ADMIN_ID = '5fb3b2dcad060c0a845047f3'

class FeathersStore{  
   
    favoritesArray = [];
    wishlistId = "";
    hasLocationPermission= false;
    isAuthenticated= false;
    user = null;
    categories = [];
    settings = {};
    cartLength = 0;
    goToAddressHelper = false;
    orderItem = {};
    searchCategory = {}; //active search category ;  
    language = "el";   
    
    constructor(){
      makeObservable(this,  {
        favoritesArray: observable,
        wishlistId: observable,
        hasLocationPermission: observable,
        isAuthenticated: observable,
        user: observable,
        categories: observable,
        settings: observable,
        cartLength: observable,
        goToAddressHelper: observable,
        orderItem: observable,        
        searchCategory: observable, 
        language: observable,       
        setFavoritesArray: action,
        setWishlistId: action,
        setHasLocationPermission: action,
        setIsAuthenticated: action,
        setUser: action,
        setCategories: action,
        setSettings: action,
        setObservables: action,       
        setGoToAddressHelper: action,
        setOrderItem: action,
        setSearchCategory: action,
        setLanguage: action             
      })
    }

    setSearchCategory = data => {      
      this.searchCategory = data;
    }

    setOrderItem = data => {
      const clonedOrderItem = cloneDeep(this.orderItem);
      const mergedObject = {...clonedOrderItem, ...data};
      this.orderItem = mergedObject;
    }

    setGoToAddressHelper = value => {
      this.goToAddressHelper = value;
    }

    setFavoritesArray = value => {
      this.favoritesArray = value;
    }

    setWishlistId = value => {
     this.wishlistId = value;
    }

    setHasLocationPermission = value => {
      this.hasLocationPermission = value;
    }

    setIsAuthenticated = value => {
      this.isAuthenticated = value;
    }

    setUser = value => {
      this.user = value;
    }
    
    setCategories = value => {
      this.categories = value;
    }

    setSettings = value => {
      this.settings = value;
    }

    setCartLength = value => {
      this.cartLength = value;   
    }

    setLanguage = value => {
      this.language = value;   
    }
   
  connect = async () => {

    //ESTABLISH FEATHERS CONNECTION

    const options = {
        transports: ['websocket'],
        forceNew: true, 
        pingTimeout: 3000, 
        pingInterval: 5000  
      };
    this.app = feathers();                     
    this.socket = io(API_URL,  /* options*/ );    
    // this.feathersAuthClient = require('@feathersjs/authentication-client').default;


    this.app
        .configure(socketio(this.socket)) 
        // .configure(feathers.socketio(this.socket))
        .configure( auth({            
            storage: window.localStorage // To store our accessToken
        }))
        .configure(rx({                           // add feathers-reactive plugin
            idField: '_id'
        }));

    this.isConnecting = true;
    
    await this.setObservables();
    
    this.app.io.on('connect', async () => {
      this.isConnecting = false;
      try{         
         await this.setObservables();
          console.log('authenticated after reconnection');
      }catch(error) {
          console.log('error authenticating after reconnection', error);
      };
    });

    this.app.io.on('disconnect', () => {
      console.log('disconnected');
      this.isConnecting = true;
    });
  }
  
    setObservables = async() => {
       try{
          const auth = await this.app.reAuthenticate();
          const { user } = await this.app.get('authentication');
          this.setUser(user);          
          this.user && (this.setIsAuthenticated(true));  
          const tree = await this.app.service('categories').get("5d0367d6d31be01e208545a6"); 
          const settingsArray = await this.app.service('settings').find({query: {}});  
          this.setCategories(tree.youTree);  
          this.setSettings(settingsArray[0]);                       
          const data = await this.wishlist();         
          data && (this.setFavoritesArray(data[0].items));          
          data && (this.setWishlistId(data[0]._id));                  
        }catch{

        }
    }
 
    login = async (email, password) => {        
        return this.app.authenticate({
            strategy: 'local',
            email,
            password
          });
    };

    
  
    logout = async () => {
      await this.app.logout();           
      this.setUser(null);
      this.setIsAuthenticated(false);     
      this.setSettings({});
      this.setGoToAddressHelper(false);
    }

    sendApplication = (firstname, lastname, email, password, phone) => {
      let payload = {
        email: "defaultUser@gmail.com",
        password: ")j~nKj/,N}N6,8&cVVV#G!=F*y",
        strategy: 'local'
      }
      return this.app.authenticate(payload).then(() => { 
        return new Promise(async(resolve, reject) => {        
          try{
            const application = await this.app.service('applications').create({
              firstname, lastname, email, password, phone,
              processed: false
            });
            return resolve(application);    
          }
         catch(error){
           return resolve(error);
          }
      })
     
    })
    }

    facebookAuth(access_token){
      return this.app.authenticate({
        strategy: "facebook",
        access_token
      })
    }

    authAndGetUser = (email) => {
      let payload = {
        email: "defaultUser@gmail.com",
        password: ")j~nKj/,N}N6,8&cVVV#G!=F*y",
        strategy: 'local'
      }
      return this.app.authenticate(payload).then(() => { 
        return new Promise(async(resolve, reject) => {        
          try{
            const user = await this.app.service('users').find({
                query: { 
                    email
                }
            });            
            return resolve(user);    
          }
         catch(error){
           return reject(error);
          }
      })
    })
    }

    async sendEmail(userId, email, firstname, lastname){     
      let random4digit = Math.floor(1000 + Math.random() * 9000);
        const emailToSend = {
          userId,
          applicationId: 'FORGOT_PASSWORD',
          random4digit,
          from: 'appdate.gr@gmail.com',
          to: `${email}`,
          subject: 'ΝΕΟ PASSWORD',
          html: `Αγαπητέ/ή ${firstname} ${lastname} ,<br>
                  Το νέο password είναι: ${random4digit} . <br>
                  Μπορείτε να κάνετε login με το email σας και το νέο password. <br>
                  Κατόπιν μπορείτε να αλλάξετε το password με κάποιο δικό σας.` 
       };
       try{
          return await this.app.service('emails').create(emailToSend); 
        }catch(err){
          console.log('Could not send email FROM sendEmail method: ', err )
        };
    }

    newestEmail(applicationId){      
      let payload = {
        email: "defaultUser@gmail.com",
        password: ")j~nKj/,N}N6,8&cVVV#G!=F*y",
        strategy: 'local'
      }
      return this.app.authenticate(payload).then(() => { 
         return this.app.service('emaillog')   
                    .find(
                      {
                        query: { 
                            applicationId: applicationId,                   
                            $sort: {createdAt: -1},
                            $limit: 1
                        }
                    }
                    ); 
            })
    }

    createUser = async(firstname, lastname, email, password, phone) => {
        const userData = {firstname, lastname, email, password, phone, 
          role: 'customer',
          enabled: true};
        return await this.app.service('users').create(userData); 
    }

    patchUser(userId, firstname, lastname, phone){
      const payload = {firstname, lastname, phone};
      return this.app.service('users').patch(userId, payload);
    }

    async patchAddresses(userId, addresses){     
      return await this.app.service('users').patch(userId, {addresses});
    }

    async updateApplicationToProcessed(id){
        return await this.app
          .service('applications')
          .patch(id, { processed: true });
    }

    async changePassword(newPassword){
      return await this.app.service('users')
        .patch(this.user._id, {password: newPassword});
    }

    getCategoriesTree(){
    return this.app.service('categories').get("5d0367d6d31be01e208545a6");
    }
    
    async productsPerCategoryX(depth, categoryId, searchText = ""){
      const cat = `category${depth}.id`;
      let query = {      
        name: { $regex: searchText, $options: 'i' },
        enabled: true,
        $sort: {
          productPriority: -1
        }
      }; 
      query[cat]=categoryId;      
      return await this.app.service('products')   
      .find({
          query
        }); 
    }
  
    async productsPerCategory(categoryId, searchText = ""){     
      let query = {      
        name: { $regex: searchText, $options: 'i' },
        enabled: true ,                
        $or: [
          {"category1.id": categoryId},
          {"category2.id": categoryId},
          {"category3.id": categoryId},
          {"category4.id": categoryId},
          {"category5.id": categoryId},
        ],
        $sort: {
          productPriority: -1
        }
      }; 
      
      return await this.app.service('products')   
      .find({
          query
        }); 
    }

    async products(searchText = ""){       
      return await this.app.service('products')   
      .find({
          query: { 
            name: { $regex: searchText, $options: 'i' },
            enabled: true,
            $sort: {
              productPriority: -1
            }
          }
        }); 
    }    

    async getProduct(productId){
      return await this.app.service('products').get(productId);
    }

    async offers(){
      return await this.app.service('products')   
      .find({
          query: { 
           offer: true,
           enabled: true  
          }
        }); 
    }

    createWishlist = async() => {
      const payload = {
        userId : this.user._id,
        items : this.favoritesArray
        };      
      return await this.app.service('wishlist').create(payload); 
    }

    updateWishlist = async() => {
      const payload = {
        userId: this.user._id,
        items: this.favoritesArray 
        };
      return await this.app.service('wishlist').update(this.wishlistId, payload); 
    }

    async wishlist(){
      return await this.app.service('wishlist')   
      .find({
          query: { 
           userId: this.user._id,  
          }
        }); 
    }

    async wishlistProducts(){     
      return await this.app.service('products')   
      .find({
          query: { 
            _id: {
              $in: [...this.favoritesArray] 
            },
            enabled: true
          }
        }); 
    }

    createOrder = async(payload) => { 
      return await this.app.service('orders').create(payload); 
    }

    ordersPerUser = async() => {
      return await this.app.service('orders')   
      .find({
          query: { 
           customerId: this.user._id,  
          }
        });
    }

    async popularProducts(){     
      return await this.app.service('popular')   
      .find(); 
    }
    
    getSettings = async() => {
      return await this.app.service('settings')   
      .find({
        query: { 
          adminId: ADMIN_ID  
        }
      });
    }

}

export default new FeathersStore()