import React, {useState, useEffect} from 'react'
import ProductCard from '../cards/productCard/ProductCard'
import Pagination from './pagination/Pagination'
import ProductPageCounter from '../screenComponents/productPageCounter';
import NavigationBar from '../screenComponents/navigationBar';
import { inject, observer } from "mobx-react";
import { useHistory } from 'react-router-dom';
import * as cloneDeep from 'lodash/cloneDeep';
import { CSSTransition } from 'react-transition-group';
import {Alert} from 'react-bootstrap';
import Popular from './popular';
import { useTranslation } from 'react-i18next';

let initialCartBiggestId = 0;
let cart = [];

const ListProducts = ({ location, feathersStore })=> {  

  const { t, i18n } = useTranslation('common');
  const history = useHistory();

  const [products, setProducts] = useState([]);  
  const [popularProducts, setPopularProducts] = useState([]);
  const [cartIncreased, setCartIncreased] = useState(false);
  const [favoritesUpdated, setFavoritesUpdated] = useState(false);
  
  useEffect(() => {
    if(localStorage.getItem('fire_cart')){
      cart = JSON.parse(localStorage.getItem('fire_cart'));    
      if(cart.length > 0)
        initialCartBiggestId = +cart.map(e => e.id).sort().reverse()[0] + 1;         
    }
  }, []);

  useEffect(()=> {
    i18n.changeLanguage(feathersStore.language);    
  },[feathersStore.language]);

  useEffect(()=>{
    feathersStore.isAuthenticated && fetchProducts();   
  },[feathersStore?.searchCategory, feathersStore.isAuthenticated, feathersStore?.favoritesArray])

  const fetchProducts = async() => {       
    let feathersProducts;
    if(feathersStore.searchCategory?.categoryId){
      const { categoryId, searchText } = feathersStore?.searchCategory;
      feathersProducts = await feathersStore.productsPerCategory(+categoryId, searchText);     
    }
    else {
      feathersProducts = await feathersStore.products(feathersStore.searchCategory?.searchText);          
    }
    setProducts([...feathersProducts]);
   
    const feathersPopularProducts = await feathersStore.popularProducts();
    setPopularProducts([...feathersPopularProducts]);
  }

  const getMainImage = product => {
    let imageArr = [];
    if(product.hasVariation){
      imageArr = product.variations
        .map(variation => variation.images)
        .flat()
        .filter(image => image.focused);        
    }else{
      imageArr = product.images
      .filter(image => image.focused);       
    }  
    return imageArr && imageArr[0];
  }

  const getBadges = product => {    
    if(product.hasVariation){
      let vrtion = product.variations
        .find(variation => variation.images.map(image => image.focused));
      return vrtion.badges;
    }
    return product.badges;
  }

  const getOldPrice = product => {
    if(product.hasVariation){
      let vrtion = product.variations
        .find(variation => variation.images.map(image => image.focused));
      return vrtion.oldPrice;
    }
    return product.oldPrice;
  }

  const getPrice = (product) => {      
    if(product.hasVariation){
      let priceArr = [];
      priceArr = product.variations
        .map(variation => +variation.retailPrice)
        const low = Math.min(...priceArr)
        const high = Math.max(...priceArr)
        if(low === high){
            return high.toFixed(2)
        }else{
            return low.toFixed(2) + " - " + high.toFixed(2)
        }          
    }else{
        return (+product.retailPrice).toFixed(2)
    }
  }

  const handlePress = (product)=> {
    history.push({
      pathname: `/product/${product._id}`,
      payload: product
    }); 
  }

  const onPressAddToFavorites = async product => {    
    let id = '';
    id = product._id;
    if(feathersStore.favoritesArray.includes(id)){
      const index = feathersStore.favoritesArray.indexOf(id);
      if (index > -1) {
        feathersStore.favoritesArray.splice(index, 1);
      }     
    }else{
      feathersStore.favoritesArray.push(id);  
    }   
    if(feathersStore.wishlistId === ""){
        const data = await feathersStore.createWishlist();        
        data && (feathersStore.wishlistId = data._id); 
    }else await feathersStore.updateWishlist();
    setFavoritesUpdated(true);
  };

  const handleAddToCart = (prod, index) => { //fromList = prod is coming from the footer list and has no variations
   
    let cartItem = {
      id: initialCartBiggestId,
      product_id: prod._id,
      title: prod?.name,
      image: prod.images?.find(img => img.focused),
      price: prod?.retailPrice,
      quantity: 1,
      totalPrice: (+prod.retailPrice).toFixed(2),
      variationTitle: null,
      variation: null,
      stock: prod?.handleStock && prod?.stock,
      extra: [],
      handleStock: prod?.handleStock
    }
  
    const cartSimilarItem = cart.find(item => item.product_id === cartItem.product_id );  
    if(cartSimilarItem){  
      const newQ = +cartSimilarItem.quantity + 1;            
      if(prod.handleStock && (newQ > cartItem.stock)){
        setWarning(index);   
        return;
      }else{
        const cartIndex = cart.findIndex(item => item.id === cartSimilarItem.id);
        cartItem.quantity = newQ;
        cartItem.totalPrice = (+cartItem.price * newQ).toFixed(2);
        cart.splice(cartIndex, 1, cartItem);
      }          
    }else{   
      cart.push(cartItem);
      feathersStore.setCartLength( feathersStore.cartLength + 1);    
    } 
    if(!prod.warning) {
      localStorage.setItem("fire_cart", JSON.stringify(cart));
      setCartIncreased(true);      
    }   
  }

  const setWarning = (index) => {
    let productsClone = [...products];
    let productClone = cloneDeep(products[index]);
    Object.assign(productClone, {warning: true});
    productsClone.splice(index, 1, productClone);
    setProducts([...productsClone]);
  }

  const removeWarning = (index) => {
    let productsClone = [...products];
    let productClone = cloneDeep(products[index]);
    Object.assign(productClone, {warning: false});
    productsClone.splice(index, 1, productClone);
    setProducts([...productsClone]);
  }

  const onCloseAlert = () => {
   setCartIncreased(false);
  }

  

//----------------Pagination Logic---------------------------------
  const [productsPerPage, setProductsPerPage] = useState(9)
  const [currentPage, setCurrentPage] = useState(0)
  const [dataLength, setDataLength] = useState(0) 
  const [pageCount, setPageCount] = useState(0)
  const [offset, setOffset] = useState(0)
  const [tableData, setTableData] = useState([])

  useEffect(()=> {
    setDataLength( products.length)
  },[products])    
  
  useEffect(()=> {      
      setPageCount(Math.ceil(dataLength / productsPerPage))        
  },[dataLength, productsPerPage])
  
  useEffect(()=> {
    const productsClone = [...products];      
    setTableData(productsClone.slice(offset, offset + productsPerPage))
  },[products, currentPage])

  const handleClick = (e)=> {
    const { selected: selectedPage } = e       
      setCurrentPage(selectedPage)        
      setOffset(selectedPage * productsPerPage)
  }
//---------------------------------------------------------------------

  const renderProducts = tableData.map( (product, index) => (

    
      <ProductCard
        key={product._id}
        stock={product.handleStock && product.stock}
        image={getMainImage(product)}
        title={product.name}
        price={getPrice(product)}
        oldPrice={getOldPrice(product)}
        onClick={()=> handlePress(product)}
        onClickCart={()=> handleAddToCart(product, index)}
        hasVariation={product.hasVariation}
        onClickForward={()=> handlePress(product)}
        warning={product.warning}
        onCloseWarning={() => removeWarning(index)}
        favorite={feathersStore.favoritesArray.includes(product._id)}
        onPressAddToFavorites={() => onPressAddToFavorites(product)}
        badges={getBadges(product)}
      />
  
  )
);
    
  return(
    <>       
      <ProductPageCounter
        currentPage={currentPage}
        productsPerPage={productsPerPage}
        dataLength={tableData.length}
        listlength={products.length}
      />
      <div className="listproducts-container">
        {renderProducts}
      </div>  
        <Pagination
          pageCount={pageCount}
          onPageChange={(e)=> handleClick(e)}
      />
      {feathersStore.isAuthenticated &&
      <div className="popular-container">
        <Popular
          handleAddToCart={handleAddToCart}
          getPrice={getPrice}
          getOldPrice={getOldPrice}
          getBadges={getBadges}
          handlePress={handlePress}
          popularProducts={popularProducts}
        />
      </div>}
      <div className="cart-updated-alert">
        <CSSTransition
            in={cartIncreased}
            timeout={300}
            classNames="alert"
            unmountOnExit
          >
            <Alert variant="info"
              dismissible
              onClose={onCloseAlert}>
                <Alert.Heading>{t("common:updated")}</Alert.Heading> 
                <p>{t("common:cart-updated")}</p>
            </Alert>
        </CSSTransition>
      </div>
      <div className="cart-updated-alert">
        <CSSTransition
            in={favoritesUpdated}
            timeout={300}
            classNames="alert"
            unmountOnExit
          >
            <Alert variant="info"
              dismissible
              onClose={() => setFavoritesUpdated(false)}>
                <Alert.Heading>{t("common:updated")}</Alert.Heading> 
                <p>{t("common:wishlist-updated")}</p>
            </Alert>
        </CSSTransition>
      </div>
    </>
  )
}

export default inject('feathersStore')(observer(ListProducts));