import React, { useEffect } from 'react';
import { inject, observer } from "mobx-react";
import { useTranslation } from 'react-i18next';

const ProductPageCounter = ({currentPage, productsPerPage, dataLength, listlength, feathersStore})=> {

  const { t, i18n } = useTranslation('common');

  useEffect(()=> {
    i18n.changeLanguage(feathersStore.language);    
  },[feathersStore.language]);

  return(
    <div className="container" style={{marginBottom: 10}}>
      <div className="total-list">
        <p style={{alignSelf: 'baseline'}}>
          {t("common:you-see")} {currentPage === 0 ?  1 : 
          productsPerPage * currentPage +1}-{currentPage === 0 ? dataLength : 
          (productsPerPage * currentPage + dataLength)}  {t("common:from")} {listlength} {t("common:products")}</p>
      </div>
    </div>
  )
}

export default inject('feathersStore')(observer(ProductPageCounter));