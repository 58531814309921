import React, { useEffect } from 'react';
import CartTable from '../../components/cards/cartTable';
import PageTitle from '../../components/screenComponents/pageTitle';
import { inject, observer } from "mobx-react";
import "./Cart.css";
import { useTranslation } from 'react-i18next';


const Cart = ({feathersStore})=> {
  
  const { t, i18n } = useTranslation('cart');

  useEffect(()=> {
    i18n.changeLanguage(feathersStore.language);    
  },[feathersStore.language]);
  
  return(
    <div className="container">
      <div className="cart-body">
        <PageTitle title={t("cart:cart")}/>
        {feathersStore.cartLength !== 0 ? <CartTable/> : <div className="empty-cart"/>}
      </div>
    </div>
  )
}

export default inject('feathersStore')(observer(Cart));