import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { createMuiTheme , makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { green, red  } from '@material-ui/core/colors';


const GreenSubmitButton = ({ title, loading, disabled, onClick, color })=> {

    function changeCursor(e) {
        e.target.style.cursor = 'pointer';
      }

      const useStyles = makeStyles((theme) => ({
        margin: {
          margin: theme.spacing(1),
        },
      }));
      
      const theme = createMuiTheme({
        palette: {
          primary: red,
          secondary: green,
        },
      });
    const classes = useStyles();
    
    return(
        <ThemeProvider theme={theme}>
          <Button 
            onClick={onClick} 
            variant="contained" 
            color={color || "secondary"} 
            style={{color: '#fff'}} 
            className={classes.margin}
            disabled={disabled}>
            {title}
            {loading && 
              <CircularProgress 
                color="primary" 
                style={{ marginLeft: 6 }} 
                size={'1.5rem'}            
              />                  
            }
          </Button>
        </ThemeProvider> 
    )
}

export default GreenSubmitButton;