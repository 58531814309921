import React from 'react'
import AddToCartSmall from '../../buttons/addToCartSmall'
import Colors from '../../assets/colors'
import AddToWishSmall from '../../buttons/addToWishSmall'
import NavigateNext from '@material-ui/icons/NavigateNext'
import { CSSTransition } from 'react-transition-group';
import {Alert} from 'react-bootstrap';
import { inject, observer } from "mobx-react";
import './ProductCard.css';

const ProductCard = ({stock, hasVariation, onClickForward, title, onPressAddToFavorites,
  warning, price, oldPrice, image, onClick, onClickCart, onCloseWarning, favorite, feathersStore, badges})=> {

  return(
    <div className="product-card">     
      <button onClick={onClick} className="shoppingCart-button">
        <img alt="" src={`https://ffi.appdate.gr/images/${image?.name}`} />
        {(badges?.includes('new')) &&  (
          <div className="new-label-container">
            <p className="label">NEW</p>
          </div>)
        }
        {((badges?.includes('sale')) || oldPrice) &&  (
          <div className="discount-label-container">
            <p className="label">SALE</p>
          </div>)
        }
        {(badges?.includes('hot')) &&  (
          <div className="hot-label-container">
            <p className="label">HOT</p>
          </div>)
        }          
      </button>      
      <div className="title-container">
        <p>{title}</p>
      </div>
      <div className="product-price-container">
        <div className="prices-container">
          {oldPrice &&
            <p  className="old-price">
              <span>{oldPrice}€</span></p>
          }
          <p  style={{color: Colors.primary}}>
            {price}€</p> 
        </div>    
        {feathersStore.isAuthenticated && feathersStore.user?.firstname !== "default" && 
          <AddToWishSmall 
            onClick={onPressAddToFavorites}
            favorite={favorite}
          />}          
        {stock !== 0? <>{hasVariation ?       
          <button className="btn" onClick={onClickForward}>
            <NavigateNext style={{color: Colors.primary, fontSize: 36, marginBottom:-10}}/>
          </button>       
          :                
          <AddToCartSmall onClick={onClickCart}/> }</>
          :
          <img alt="" className="soldout-img" src={require(`../../img/sold-out.png`)} />
        }
      </div>
      <CSSTransition
          in={warning}
          timeout={300}
          classNames="alert"
          unmountOnExit
        >
          <Alert variant="danger"
            dismissible
            onClose={onCloseWarning}>
              <Alert.Heading>Προσοχή!</Alert.Heading> 
              <p>Δεν μπορείτε να προσθέσετε περισσότερα τεμάχια στο καλάθι σας από το απόθεμα!</p>
          </Alert>
        </CSSTransition>                    
    </div>
    )
}

export default inject('feathersStore')(observer(ProductCard));