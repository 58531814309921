import React,{useState, useEffect, useRef, Suspense} from 'react'
import PageTitle from '../../components/screenComponents/pageTitle'
import PayPal from '../../components/buttons/paypal'
import CreditCardButton from '../../components/buttons/creditCardButton'
import CheckoutComponent from '../../components/cards/checkoutComponent'
import {SlideDown} from 'react-slidedown';
import Registration from '../../components/reusable/registration/Registration'
import LoadingOrderModal from '../../components/modals/loadingOrderModal';
import OrderCompleteModal from '../../components/modals/orderCompleteModal';
import 'react-slidedown/lib/slidedown.css';
import { inject, observer } from "mobx-react";
import { useHistory } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import { PayPalButton } from "react-paypal-button-v2";
import "./Checkout.css";
import { useTranslation } from 'react-i18next';

const Checkout = ({ feathersStore })=> {

  const history = useHistory();  

  const { t, i18n } = useTranslation(['common', 'checkout']);
 
  const origins = [
    {value: 1, label : 'Αττική'}, 
    {value: 2, label: 'Ανατολική Μακεδονία και Θράκη'} , 
    {value: 3, label: 'Κεντρική Μακεδονία'}, 
    {value: 4, label: 'Δυτική Μακεδονία'}, 
    {value: 5, label: 'Ήπειρος'}, 
    {value: 6, label: 'Θεσσαλία'},
    {value: 7, label: 'Ιόνια νησιά'}, 
    {value: 8, label: 'Δυτική Ελλάδα'}, 
    {value: 9, label: 'Κεντρική Ελλάδα'}, 
    {value: 10, label: 'Πελοπόνησος'}, 
    {value: 11, label: 'Βόρειο Αιγαίο'}, 
    {value: 12, label: 'Νότιο Αιγαίο'}, 
    {value: 13, label: 'Κρήτη'}
  ];

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    }
  }));

  const classes = useStyles(); 

  const formRef = useRef();
  
  const [cartSub, setCartSub] = useState(0);  
  const [isLoading, setLoading] = useState(false);
  const [isComplete, setComplete] = useState(false);
  const [transportation, setTransportation] =useState({}); //Object containing fields regarding transportation settings 
  const _isMounted = useRef(true); //used in line 405 to check if component is mounted
  
  const cartItems = JSON.parse(localStorage.getItem('fire_cart'));

  useEffect(() => {
    return () => { // ComponentWillUnmount in Class Component
        _isMounted.current = false;
    }
  }, []);

  useEffect(()=> {
    const _subTotal =  cartItems.map(item=> item.totalPrice)
      .reduce((prev, curr)=> +prev + +curr, 0).toFixed(2);
    setCartSub(_subTotal)
  },[cartItems])

  useEffect(()=> {
    i18n.changeLanguage(feathersStore.language);    
  },[feathersStore.language]);

  useEffect(()=> {
    setTransportation(feathersStore.settings?.transportation);    
  },[feathersStore.settings?.transportation]);

  useEffect(()=> {
    if(feathersStore.isAuthenticated && feathersStore.user?.firstname !== 'default'
      && formRef.current){
        formRef.current.setFieldValue('firstName', feathersStore.user?.firstname);
        formRef.current.setFieldValue('lastName', feathersStore.user?.lastname);
        feathersStore.user?.addresses && formRef.current.setFieldValue('street',feathersStore.user?.addresses.find(addr => addr.active).street);
        feathersStore.user?.addresses && formRef.current.setFieldValue('streetNumber',feathersStore.user?.addresses.find(addr => addr.active).streetNumber);
        feathersStore.user?.addresses && formRef.current.setFieldValue('city',feathersStore.user?.addresses.find(addr => addr.active).city);
        feathersStore.user?.addresses && formRef.current.setFieldValue('origin',feathersStore.user?.addresses.find(addr => addr.active).origin);
        feathersStore.user?.addresses && formRef.current.setFieldValue('postcode',feathersStore.user?.addresses.find(addr => addr.active).postcode);
        formRef.current.setFieldValue('phone',feathersStore.user?.phone);
        formRef.current.setFieldValue('email',feathersStore.user?.email);
      }  
  },[feathersStore.user]);

 
  const setTitle = (item)=> {
    if(item.variation === null){
        return item.title
    }else{
        return item.title + " - " + item.variation
        + item.extra?.map(feature => " - " + feature.name);
    }
  }

  const createOrder = (data, actions) => {
    let values;
    if (formRef.current) {
      values = formRef.current.values;
    }
    return actions.order
      .create({
        purchase_units: [
          {
            amount: {
              currency: "EUR",
              value: values.grandTotal,
            },
          },
        ],
        application_context: {
           shipping_preference: "NO_SHIPPING" // default is "GET_FROM_FILE"
         }
      })
      .then((orderID) => {       
        feathersStore.setOrderItem({paypalOrderID: orderID});     
        return orderID;
      });
  }

  const approved = (data, actions) =>  {
    let values;
    if (formRef.current) {
      values = formRef.current.values;
    }
    // This function captures the funds from the transaction.
    return actions.order.capture().then(function(details)  {
      // This function shows a transaction success message to your buyer.
      sendOrder(values);
      alert('Transaction completed by ' + details.payer.name.given_name);
     });
   }
  
  const onInit = (data, actions) =>  {
    let formikRef;
    if (formRef.current) {
      formikRef = formRef.current;
    }
  // Disable the buttons
    actions.disable();

   //formikRef.handleSubmit();

    // Listen for changes in formik
  //  formikRef.onChange(() =>  {
   //  formikRef.errors.addEventListener('change', function(event)  {     
      if (formikRef.isValid)  {
        actions.enable();
        } else  {
        actions.disable();
        }
   //   });
  }

  const onClick = () => {
    let formikRef;
    if (formRef.current) {
      formikRef = formRef.current;
    }

    if (!formikRef.isValid) {    
      alert("Please fill all fields");
    }
  }

  const sendOrder = values => {   
    const day = new Date().getDate(); //Current Date
    const month = new Date().getMonth() + 1; //Current Month
    const year = new Date().getFullYear(); //Current Year
    const hours = new Date().getHours(); //Current Hours
    const min = new Date().getMinutes(); //Current Minutes
    const initDate = day + '/' + month + '/' + year + ' ' + hours + ':' + min;
    feathersStore.setOrderItem({
        'customerId': feathersStore.user._id || "",
        'bookingDate': initDate,
        'status': 'Pending',
        'items': [...cartItems],
        'total': values.grandTotal,
        'transportationCost': values.transportationCost,
        'handoverCost': values.handoverCost,
        'processed': false,     
        'latitude': 0,
        'longitude': 0,
        'firstName': values.firstName,
        'lastName': values.lastName,
        'street': values.street,
        'streetNumber': values.streetNumber,
        'city': values.city, 
        'origin': values.origin, 
        'apartment' : feathersStore.isAuthenticated && feathersStore.user?.firstname !== 'default' ? feathersStore.user?.phone : "", 
        'postcode': values.postcode,        
        'phone': values.phone,
        'email': values.email,
        'hasOtherAddress': values.hasOtherAddress,
        'deliveryFirstName': values.hasOtherAddress ? values.deliveryFirstName : "",
        'deliveryLastName': values.hasOtherAddress ? values.deliveryLastName : "",
        'deliveryStreet': values.hasOtherAddress ? values.deliveryStreet : "",
        'deliveryCity': values.hasOtherAddress ? values.deliveryCity : "", 
        'deliveryOrigin': values.hasOtherAddress ? values.deliveryOrigin : "", 
        'deliveryPostcode': values.hasOtherAddress ? values.deliveryPostcode : "",       
        'deliveryPhone': values.hasOtherAddress ? values.deliveryPhone : "",
        'comments':values.comments || "",
        'paymentMethod':values.paymentMethod 
    });
    setLoading(true);

  //  console.log("feathersStore.orderItem: ", feathersStore.orderItem)
  }

  const grandTotalComponent = ({field, form}) => {
    /*
    form.setFieldValue(field.name, +cartSub + (form.values.paymentMethod === 'Cash' ? +transportation?.handover : 0)
    + (+cartSub >= +transportation?.free ? 0 : 
    (+form.values.origin === 1 ? +transportation?.downtown : +transportation?.countryside)))
      */
    return(
    <p>{field.value}€</p>
    )
    }
  
  

  //----------------- LoadingOrderModal controls -----------------

  const whenError = () => {
    setLoading(!isLoading); 
  }

  const CompleteOrder = ()=> {
    setLoading(!isLoading);
    setComplete(!isComplete);     
  }

  const setOrder = ()=> {
    setComplete(!isComplete);      
    history.push("/") ;     
  }

  const renderCartItems = cartItems.map( (item, index) => (
    <CheckoutComponent
      key={index}
      title={setTitle(item)}
      quantity={item.quantity}
      total={item.totalPrice}
    />
  ))

  
  return(   
    <>    
    <LoadingOrderModal
      visible={isLoading}
      whenSent={CompleteOrder}
      whenError={whenError}
    />
    <OrderCompleteModal
      visible={isComplete}
      onClickFinish={setOrder}        
    /> 
   
    <div className="container">
      <div className="cart-body" style={{minHeight: '5vh'}}>
        <PageTitle title={t('common:checkout')}/>
      </div>
    </div>
    
        <>
    <Formik 
      innerRef={formRef}  
      initialValues={{
        firstName: feathersStore.isAuthenticated && feathersStore.user?.firstname !== 'default' ? feathersStore.user?.firstname : "",
        lastName: feathersStore.isAuthenticated && feathersStore.user?.firstname !== 'default' ? feathersStore.user?.lastname : "",
        street: feathersStore.isAuthenticated && feathersStore.user?.firstname !== 'default' && feathersStore.user?.addresses ? 
          feathersStore.user?.addresses.find(addr => addr.active).street : "",
        streetNumber: feathersStore.isAuthenticated && feathersStore.user?.firstname !== 'default' && feathersStore.user?.addresses ? 
          feathersStore.user?.addresses.find(addr => addr.active).streetNumber : "",
        city: feathersStore.isAuthenticated && feathersStore.user?.firstname !== 'default' && feathersStore.user?.addresses ? 
          feathersStore.user?.addresses.find(addr => addr.active).city : "", 
        origin: feathersStore.isAuthenticated && feathersStore.user?.firstname !== 'default' && feathersStore.user?.addresses ? 
          feathersStore.user?.addresses.find(addr => addr.active).origin : 1, 
        postcode: feathersStore.isAuthenticated && feathersStore.user?.firstname !== 'default' && feathersStore.user?.addresses ? 
          feathersStore.user?.addresses.find(addr => addr.active).origin : "",       
        phone: feathersStore.isAuthenticated && feathersStore.user?.firstname !== 'default' ? feathersStore.user?.phone : "",
        email: feathersStore.isAuthenticated && feathersStore.user?.lastname !== 'default' ? feathersStore.user?.email : "",
        hasOtherAddress: false,
        deliveryFirstName: '',
        deliveryLastName: '',
        deliveryStreet: '',
        deliveryCity: '', 
        deliveryOrigin: 1, 
        deliveryPostcode: '',       
        deliveryPhone: '',
        comments:'',
        paymentMethod:'Cash',
        acceptedTerms: false,
        grandTotal:0,
        transportationCost: 0,
        handoverCost: 0      
      }}
      
      validationSchema={Yup.object({
        firstName: Yup.string()
          .max(15, 'Must be 15 characters or less')
          .required('Required'),
        lastName: Yup.string()
          .max(20, 'Must be 20 characters or less')
          .required('Required'),
        street: Yup.string()
          .max(40, 'Must be 40 characters or less')
          .required('Required'),
        streetNumber: Yup.string()
          .max(10, 'Must be 10 characters or less')
          .required('Required'),
        city: Yup.string()
          .max(20, 'Must be 20 characters or less')
          .required('Required'), 
        grandTotal: Yup.number(),  
        transportationCost: Yup.number(),  
        handoverCost: Yup.number(),  
        origin: Yup.number()
          .oneOf(
            [...origins.map(origin => origin.value)],
            'Invalid Origin'
          )
          .required('Required'),
        postcode: Yup.string()
          .max(10, 'Must be 10 characters or less')
          .required('Required'),  
        email: Yup.string()
          .email('Invalid email address')
          .required('Required'), 
        phone: Yup.string()
          .matches(phoneRegExp, 'Phone number is not valid')        
          .required('Required'),         
        hasOtherAddress:  Yup.boolean()
          .required('Required'),
        comments: Yup.string()
          .max(500, 'Must be 500 characters or less'),
        paymentMethod:  Yup.string() 
          .required('Required')
          .oneOf(["Cash", "ClickAway","PayPal"], 'Invalid Payment Method.'),  
         acceptedTerms: Yup.boolean()
          .required('Required')
          .oneOf([true], 'You must accept the terms and conditions.'),

          //----- Sub - form validation ----------          
        deliveryFirstName:   Yup.string().when('hasOtherAddress', {
          is: true,
          then:  Yup.string()
          .max(15, 'Must be 15 characters or less')
          .required('Required')
        }), 
        deliveryLastName:   Yup.string().when('hasOtherAddress', {
          is: true,
          then:  Yup.string()
          .max(20, 'Must be 20 characters or less')
          .required('Required')
        }),          
        deliveryStreet:Yup.string().when('hasOtherAddress', {
          is: true,
          then:  Yup.string()
            .max(40, 'Must be 40 characters or less')
            .required('Required')
        }),
        deliveryCity: Yup.string().when('hasOtherAddress', {
          is: true,
          then:  Yup.string()
          .max(20, 'Must be 20 characters or less')
          .required('Required')
        }),        
        deliveryOrigin: Yup.number().when('hasOtherAddress', {
          is: true,
          then:  Yup.number()
          .oneOf(
            [...origins.map(origin => origin.value)],
            'Invalid Origin'
          )
          .required('Required')
        }),
        deliveryPostcode: Yup.string().when('hasOtherAddress', {
          is: true,
          then:  Yup.string()
          .max(10, 'Must be 10 characters or less')
          .required('Required')
        }),
        deliveryPhone:Yup.string().when('hasOtherAddress', {
          is: true,
          then:  Yup.string()
          .matches(phoneRegExp, 'Phone number is not valid')        
          .required('Required') 
        })         
      })}
      
      onSubmit={(values, { setSubmitting }) => {  
        sendOrder(values);
        setSubmitting(false);
        ;
      }}
    >
    {formik => (
      <Form>
    <div className="container">
    <div className="checkout-body-container">
      <div className="container" style={{maxWidth: 800}}>
        {_isMounted.current && feathersStore.user?.firstname === "default" &&  
          <Registration/>}
        <div className=" row-container" >
          <div className="form-group two-quarters-container">
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <label htmlFor="firstName" style={{fontWeight: 'bold'}}>{t("common:firstName")}</label><p style={{color: 'red'}}>*</p>
            </div>
            <Field name="firstName" type="text" className="form-control" />
           <ErrorMessage name="firstName" />
          </div>
          <div className="form-group two-quarters-container-right">
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <label htmlFor="lastName" style={{fontWeight: 'bold'}}>{t("common:lastName")}</label><p style={{color: 'red'}}>*</p>
            </div>
            <Field name="lastName" type="text" className="form-control" />
            <ErrorMessage name="lastName" />
          </div>                
        </div>
        <div className="row-container">
          <div className="form-group three-quarters-container">
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <label style={{fontWeight: 'bold'}} htmlFor="street">{t("common:street")}</label><p style={{color: 'red'}}>*</p>
            </div>
            <Field name="street" type="text" className="form-control" />
            <ErrorMessage name="street" />
          </div>
          <div className="form-group one-quarter-container-right">
            <div style={{display: 'flex',  width:'25%', flexDirection: 'row'}}>
              <label style={{fontWeight: 'bold'}} htmlFor="streetNumber">{t("common:streetNumber")}</label><p style={{color: 'red'}}>*</p>
            </div>
            <Field name="streetNumber" type="text" className="form-control" />
            <ErrorMessage name="streetNumber" />
          </div>
        </div>
        <div className="form-group">
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <label style={{fontWeight: 'bold'}} htmlFor="city">{t("common:city")}</label><p style={{color: 'red'}}>*</p>
          </div>
          <Field name="city" type="text" className="form-control" />
          <ErrorMessage name="city" />
        </div>
        <div className="row-container" >
          <div className="form-group three-quarters-container">
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <label htmlFor="origin" style={{fontWeight: 'bold'}}>{t("common:origin")}</label><p style={{color: 'red'}}>*</p>
            </div>
            <Field name="origin" as="select" className="form-control" >
              {origins.map((i, index)=> (              
                <option key={index} className="option-item" value={i.value}>{i.label}</option>
              ))}             
            </Field>
            <ErrorMessage name="origin" />
          </div>
          <div className="form-group one-quarter-container-right">
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <label htmlFor="postcode" style={{fontWeight: 'bold'}}>{t("common:postcode")}</label><p style={{color: 'red'}}>*</p>
            </div>
            <Field name="postcode" type="text" className="form-control" />
            <ErrorMessage name="postcode" />
          </div>
        </div>
        <div className="form-group">
          <div style={{width: '50%', display: 'flex', flexDirection: 'row'}}>
            <label htmlFor="phone" style={{fontWeight: 'bold'}}>{t("common:phone")}</label><p style={{color: 'red'}}>*</p>
          </div>
          <Field name="phone" type="text" className="form-control" />
          <ErrorMessage name="phone" />
        </div>
        <div className="form-group">
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <label style={{fontWeight: 'bold'}} htmlFor="email">{t("common:email")}</label><p style={{color: 'red'}}>*</p>
          </div>
          <Field name="email" type="email" className="form-control" />
          <ErrorMessage name="email" />       
          <small id="emailHelp" className="form-text text-muted">{t("common:email-message")}</small>
        </div>  
        <div className="form-group form-check">
          <input 
            type="checkbox" 
            className="form-check-input" 
            id="registrationCheck"  
            disabled={feathersStore.isAuthenticated && feathersStore.user?.firstname !== 'default'}
          />
          <label className="form-check-label" htmlFor="registrationCheck">{t("checkout:create-account")}</label>
        </div>
      </div>  
      <div className="container" style={{maxWidth: 800, paddingLeft: 50}}>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <Field type="checkbox"   name="hasOtherAddress" 
            className="form-check-input"   style={{width: 20, height: 20}}/>
          <label className="form-check-label" htmlFor="hasOtherAddress" style={{marginLeft: 10, fontWeight: 'bold', fontSize: 20}}>
            {t("checkout:different-delivery-address")}
          </label>
        </div>
        <SlideDown className={'my-dropdown-slidedown'}>
          {formik.values.hasOtherAddress && 
          <div style={{marginTop: 20}}>
            <div className="row-container">
              <div className="form-group two-quarters-container">
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <label htmlFor="deliveryFirstName" style={{fontWeight: 'bold'}}>{t("common:firstName")}</label><p style={{color: 'red'}}>*</p>
                </div>
                <Field type="text" className="form-control" name="deliveryFirstName" />
                <ErrorMessage name="deliveryFirstName" />       
              </div>
              <div className="form-group two-quarters-container-right">
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <label htmlFor="deliveryLastName" style={{fontWeight: 'bold'}}>{t("common:lastName")}</label><p style={{color: 'red'}}>*</p>
                </div>
                <Field name="deliveryLastName" type="text" className="form-control" />
                <ErrorMessage name="deliveryLastName" />
              </div>                
            </div>
            <div className="form-group">
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <label style={{fontWeight: 'bold'}} htmlFor="deliveryStreet">{t("common:street-and-number")}</label><p style={{color: 'red'}}>*</p>
              </div>
              <Field name="deliveryStreet" type="text" className="form-control" />
              <ErrorMessage name="deliveryStreet" />
            </div>
            <div className="form-group">
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <label style={{fontWeight: 'bold'}} htmlFor="deliveryCity">{t("common:city")}</label><p style={{color: 'red'}}>*</p>
              </div>
              <Field name="deliveryCity" type="text" className="form-control" />
              <ErrorMessage name="deliveryCity" />
            </div>
            <div className="row-container">
              <div className="form-group three-quarters-container">
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <label htmlFor="deliveryOrigin" style={{fontWeight: 'bold'}}>{t("common:origin")}</label><p style={{color: 'red'}}>*</p>
                </div>
                <Field name="deliveryOrigin" as="select" className="form-control" >
                  {origins.map((i, index)=> (              
                    <option key={index} className="option-item" value={i.value}>{i.label}</option>
                  ))}             
                </Field>
                <ErrorMessage name="deliveryOrigin" />
              </div>
              <div className="form-group one-quarter-container-right">
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <label htmlFor="deliveryPostcode" style={{fontWeight: 'bold'}}>{t("common:postcode")}</label><p style={{color: 'red'}}>*</p>
                </div>
                <Field name="deliveryPostcode" type="text" className="form-control" />
                <ErrorMessage name="deliveryPostcode" />
              </div>
            </div>
            <div className="form-group">
              <div style={{width: '50%', display: 'flex', flexDirection: 'row'}}>
                <label htmlFor="deliveryPhone" style={{fontWeight: 'bold'}}>{t("common:phone")}</label><p style={{color: 'red'}}>*</p>
              </div>
              <Field name="deliveryPhone" type="text" className="form-control" />
              <ErrorMessage name="deliveryPhone" />
            </div>
          </div>
          }
        </SlideDown>
        <div style={{display: 'flex', flexFlow: 'column', alignItems: 'baseline', justifyContent: 'left', marginTop: 20}}>
          <label htmlFor="comments">
            {t("checkout:order-comments")}
          </label>
          <Field as="textarea" className="form-control" name="comments" rows="3"/>
        </div>      
      </div>
    </div>
    </div>   
    <div className="container" style={{paddingTop: 30}}>
      <div className="checkout-order-table-title">
        <p>{t("checkout:payment")}</p>
      </div>
      <div>
        <div className="checkout-order-table">
          <div className="checkout-order-table-paymentType">
            <Field type="radio" name="paymentMethod" value="Cash" />
            <p style={{fontWeight: 'bold', marginLeft: 5}}>{t("checkout:cash")}</p>
            <p style={{marginLeft: 15}}>({t("checkout:cash-text")}).</p>
          </div>
          <div className="checkout-order-table-paymentType">
            <Field type="radio" name="paymentMethod" value="ClickAway" />
            <p style={{fontWeight: 'bold', marginLeft: 5}}>{t("checkout:click-away")}</p>
            <p style={{marginLeft: 15}}>({t("checkout:click-away-text")}).</p>
          </div>
          <div className="checkout-order-table-paymentType">
            <Field type="radio" name="paymentMethod"  value="PayPal"/>
            <p style={{fontWeight: 'bold', marginLeft: 5}}>{t("checkout:paypal-radio")}</p>
            <span 
              hidden={formik.values.paymentMethod !== "PayPal" || formik.isValid} 
              className="paypal-error">&nbsp;&nbsp;&nbsp;&nbsp;{t("checkout:paypal-error")}</span>
          </div>
          <div className="checkout-order-table-terms">
            <p>
            {t("checkout:personal-data-info")}
            </p>
            <p>
            {t("checkout:read-the")} <a href="#">{t("common:privacy-statement")}</a>.
            </p>
          </div>
          <div className="checkout-order-table-acceptTerms">
            <span>
            <Field type="checkbox" name="acceptedTerms" />
            <span style={{fontWeight: 'bold', marginLeft: 5}}>
            {t("checkout:i-have-read")} <a href="#">{t("common:terms-of-service")}</a> 
              &nbsp;{t("checkout:of-this-site")}<span style={{color: 'red'}}>*</span></span></span>
          </div>
        </div>
      </div>
    </div>
   
    <div className="container" style={{paddingTop: 30}}>
      <div className="checkout-order-table-title">
        <p>{t("checkout:your-order")}</p>
      </div>        
        <div className="checkout-order-table">
          <div className="checkout-order-table-header">
            <p style={{fontWeight: 'bold'}}>{t("common:product")}</p>
            <p style={{fontWeight: 'bold'}}>{t("common:sub-total")}</p>
          </div>
          <div className="checkout-order-table-body">
            {renderCartItems}
          </div>
          <div className="checkout-order-table-subtotal">
            <p style={{fontWeight: 'bold'}}>{t("common:sub-total")}</p>
            <p>{cartSub}€</p>
          </div>
          <div className="checkout-order-table-subtotal">
            <p style={{fontWeight: 'bold'}}>{t("common:transportation")}</p>
            <Field name="transportationCost" 
              type="number" 
              value={formik.values.transportationCost = (cartSub >= +transportation?.free || formik.values.paymentMethod === 'ClickAway' ?
                0 : 
                +formik.values.origin === 1 ? +transportation?.downtown : +transportation?.countryside).toFixed(2)} 
              component={grandTotalComponent}>             
            </Field>               
          </div>
          <div className="checkout-order-table-subtotal">
            <p style={{fontWeight: 'bold'}}>{t("checkout:cash")}</p>
            <Field name="handoverCost" 
              type="number" 
              value={formik.values.handoverCost = (formik.values.paymentMethod === 'Cash' ? 
                +transportation?.handover : 0).toFixed(2)} 
              component={grandTotalComponent}>             
            </Field>             
          </div>
          <div className="checkout-order-table-subtotal">
            <p style={{fontWeight: 'bold'}}>{t("common:total")}</p>
            <Field name="grandTotal" 
              type="number" 
              value={formik.values.grandTotal = (+cartSub + (formik.values.paymentMethod === 'Cash' ? 
                +transportation?.handover : 0)
                + (+cartSub >= +transportation?.free || formik.values.paymentMethod === 'ClickAway' ? 0 : 
                (+formik.values.origin === 1 ? +transportation?.downtown : +transportation?.countryside))).toFixed(2)} 
              component={grandTotalComponent}>             
            </Field>
          </div>
          <div className="checkout-order-table-submit">            
            {formik.values.paymentMethod === 'Cash' || formik.values.paymentMethod === 'ClickAway'? 
            <button type="submit" disabled={formik.isSubmitting || !formik.isValid}
              className="btn btn-primary" >{t("checkout:send-order")}</button>
            :
           null} 
          </div>
        </div>      
      </div> 
      {formik.values.paymentMethod === 'PayPal' &&
      <div className= {`checkout-paypal ${!formik.isValid && "disable-paypal-buttons"}`}>  
        <div className="checkout-paypal-buttons">                            
          <PayPalButton
            onClick={onClick}                 
            onApprove={approved}
            createOrder={createOrder}
          //  style={{layout: 'horizontal', height: 55}}
            options={{
              clientId: "Ab9xBziZRnJjCwgOIgQb-uTWsr6cWG1B5nCVup5m2EcrZib1dD7V70LViTk6rL3ZvPH0Sg3IAFQDBLFZ"
            }}
          />
        </div>            
      </div>}
      
     </Form>  
    )}
     
    </Formik>
  
    </>
    <div className="checkout-order-table-footer"/>      
  </>
  )
}

export default inject('feathersStore')(observer(Checkout));