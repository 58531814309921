import React from 'react';
import "./CategoriesImageButton.css";

const CategoriesImageButton = ({image, onClick})=> {

    return(
        <button onClick={onClick} className="category-card">
            <img alt="."  src={`https://ffi.appdate.gr/images/${image}`}/>
        </button>
    )
}

export default CategoriesImageButton;