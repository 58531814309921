import React from 'react'
import { Route, Switch, Redirect } from "react-router-dom"
import HomeBody from './homeBody/HomeBody';
import TermsNConditions from '../screenComponents/termsnconditions';
import About from '../screenComponents/about';
import WishList from './wishList';
import PrivacyPolicy from '../screenComponents/privacypolicy';
import Cart from '../../screens/cart/Cart'
import Product from '../../screens/product/Product';
import Checkout from '../../screens/checkout/Checkout'
import Account from '../../screens/account/Account';
import { inject, observer } from "mobx-react";
import ListProducts from './listProducts';

const Body = ({ feathersStore })=> {
   
const  PrivateRoute: React.FC<{
    component: React.FC;
    path: string;
    exact: boolean;
}> = (props) => {

return  feathersStore.isAuthenticated && feathersStore.user?.firstname !== "default" ? 
    (<Route  path={props.path}  exact={props.exact} component={props.component} />) : 
    (<Redirect  to="/"  />);
};
     

    return(
    <div className="body-container">
    <Switch>           
        <Route path="/cart" component={Cart}/>
        <Route path="/product/:id" component={Product}/>       
        <Route path="/checkout"component={Checkout}/>
        <Route path="/wishList"component={WishList}/>  
        <Route path="/termsnconditions"component={TermsNConditions}/>
        <Route path="/privacypolicy"component={PrivacyPolicy}/>       
        <PrivateRoute  path="/account"  component={Account}  exact  />
        <Route path="/" component={HomeBody} />
    </Switch>
    </div>
    )
}

export default inject('feathersStore')(observer(Body));