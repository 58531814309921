import React from 'react';
import "./Carousel.css";

const Carousel = ()=> {

    return(     
        <div className="carousel">
          <div id="carouselExampleControls" style={{width: '100%'}} className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active">
                <img src={require('../img/carousel_1.jpg')} className="d-block w-100" alt="..."/>
                </div>
                <div className="carousel-item">
                <img src={require('../img/carousel_2.jpg')} className="d-block w-100" alt="..."/>
                </div>
                <div className="carousel-item">
                <img src={require('../img/carousel_3.jpg')} className="d-block w-100" alt="..."/>
                </div>
                <div className="carousel-item">
                <img src={require('../img/carousel_4.jpg')} className="d-block w-100" alt="..."/>
                </div>
            </div>
            <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
            </a>
        </div>
      </div>  
    )
}

export default Carousel;