import React, { useEffect } from 'react'
import {FreeShipping, CashOnDelivery} from '../../cards/siteOffers/SiteOffers'
import Carousel from '../../carousel/Carousel'
import ImageCategories from '../../cards/imageCategories/ImageCategories'
import ListProducts from '../listProducts';
import "./HomeBody.css";
import { inject, observer } from "mobx-react";
import { useTranslation } from 'react-i18next';

const HomeBody = ({ feathersStore })=> {

  const { t, i18n } = useTranslation('offers');

  useEffect(()=> {
    i18n.changeLanguage(feathersStore.language);    
  },[feathersStore.language]);  

  return(
    <>
    
      <div className="column-flex-center home-container">
        {feathersStore?.isAuthenticated && (!feathersStore.searchCategory?.searchText 
          && !feathersStore.searchCategory?.title) ? (
          <>
            <div className="column-flex-center">
              <Carousel/>
            </div>       
          
            <div className="categories-card-container">
              <ImageCategories/>
            </div>
            <div className="column-flex-center site-offers">
              <div className="offer-container">
                <FreeShipping
                  header={t("offers:shipping-title")}
                  body={t("offers:shipping-text")}
                />
                <CashOnDelivery
                   header={t("offers:cash-title")}
                   body={t("offers:cash-text")}
                />
              </div>
            </div> 
          </>)
          : null
         }        
        <ListProducts />       
     </div>
   
    </>
  )
}

export default inject('feathersStore')(observer(HomeBody));