import React from 'react';
import Colors from '../assets/colors'
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import PrivacyText from './privacyText';

const PrivacyPolicy = ()=> {
    
    return(
        <div className="body">          
            <div className="pageIcon">
                <FormatListNumberedIcon style={{fontSize: 150, color: Colors.primary}}/>
            </div>
            <h1 style={{color: Colors.primary, letterSpacing: 1}}>{`-PRIVACY POLICY-`}</h1>
            <div className="categoryView">
                <PrivacyText/>
            </div> 
        </div>
    )
}

export default PrivacyPolicy;