import React, {useState} from 'react';
import Prompt from '../../modals/prompt';
import RegistrationCard from '../../modals/registrationCard'
import VerificationCode from '../../modals/verificationCode'
import ResetPassword from '../../modals/resetPassword'
import ConfirmExitModal from '../../modals/confirmExitModal'
import RegisteredCustomer from '../../buttons/registeredCustomer'
import "./Registration.css"


const Registration = ()=> { 

  const [visible, setModal] = useState(false);
  const [visibleRegister, setRegistrationModal] = useState(false);
  const [visibleVerification, setVisibleVerification] = useState(false);
  const [resetPasswordModal, setResetPasswordModal] = useState(false)
  const [exit, setExit] = useState(false);

  const handleModal = ()=> {
    setModal(!visible); 
  }

  const openRegistrationModal = ()=> {
    setModal(false);
    setRegistrationModal(true);
  }

  const closeRegistrationModal = ()=> {
    setRegistrationModal(false);
  }

  const openVerification = ()=> {
    setRegistrationModal(false);
    setVisibleVerification(true);
  }

  const closeVerification = ()=> {
    setVisibleVerification(false);     
  } 

  const facebookLogin = ()=> {
    setModal(false);     
  }

   const SubmitReset = ()=> {
    setResetPasswordModal(!resetPasswordModal);
  } 

  const Logout = ()=> {     
    setExit(!exit);     
  }

  const NoLogout = ()=> {
    setExit(!exit);
  }  

  return(
    <div >
      <Prompt 
        visible={visible}
        onClickForgot={SubmitReset} 
        close={handleModal}       
        onClickAway={handleModal}
        onClickRegister={openRegistrationModal}
        onClickFacebook={facebookLogin}
        />
      <ResetPassword
        visible={resetPasswordModal}
        onClickSubmit={SubmitReset}
        close={SubmitReset}
      />  
      <RegistrationCard
        visible={visibleRegister} 
        close={closeRegistrationModal}
        onClickAway={closeRegistrationModal}
        onClick={openVerification}
      />       
      <VerificationCode
        visible={visibleVerification}
        onClickCloseCode={closeVerification}
        onClickAway={closeVerification}
      />
      <ConfirmExitModal
        visible={exit}
        no={NoLogout}
        yes={Logout}
      />
      <RegisteredCustomer 
        onClickSignIn={handleModal}       
      />                
    </div>
  )
}

export default (Registration);