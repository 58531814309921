import React from 'react'
import FavoriteBorder from '@material-ui/icons/FavoriteBorder'
import Colors from '../assets/colors'


const AddToCartSmall = ({ onClick, favorite })=> {


    return(
        <button 
            type="button"
            className="btn shoppingCart-button"    
            onClick={onClick}           
        >
            <FavoriteBorder style={{color: favorite ? '#a8dadc':'#e63946'}}/>
        </button>
    )
}

export default AddToCartSmall;