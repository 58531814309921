import React, { useState } from 'react'
import { FacebookButton, InstagramButton } from '../../buttons/socialButtons'
import PhoneIcon from '@material-ui/icons/PhonelinkRing';
import SignInButton from '../../buttons/signInButton'
import CartButton from '../../buttons/cartButton/CartButton';
import WishListButton from '../../buttons/wishListButton'
import AccountButton from '../../buttons/accountButton'
import DashboardButton from '../../buttons/dashboardButton';
import { inject, observer } from "mobx-react";
import FilledInput from '@material-ui/core/FilledInput';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import Colors from '../../assets/colors';
import NavigationBar from '../navigationBar';
import {Link} from 'react-router-dom';
import './TopBarBootstrap.css';

const TopBar = ({onClickSignIn, feathersStore})=> {
 
  const resetProducts = () => {
    feathersStore.setSearchCategory({});  
  }

  const toggleLanguage = lang => {
    feathersStore.setLanguage(lang);
  }
   
  return(
    <div className="top-bar-nav"  role="navigation">
      <nav className="navbar py-0 px-0 navbar-expand-lg navbar-dark bg-transparent w-100">      
        <Link to="/" onClick={() => resetProducts()} className="navbar-brand nav-nav-left" >
          <img   
            width="120"
            alt="AppDate Logo"
            src={require('../../img/shopp_Width220.png')}           
          />  
        </Link>       
        
        <div className="phone-nav nav-nav-left navbar-brand" style={{display:'inline'}}>         
          <span><a className="phone-p" href="tel:2114115591">
            <PhoneIcon/><span className="phone-number">
              {feathersStore.isAuthenticated && 
              feathersStore.settings?.phoneNumbers &&
              feathersStore.settings?.phoneNumbers.find(p => p.numberEnabled).phoneNumber}
            </span></a></span>
        </div>
          
        <div className="nav-cart-button-container nav-cart-show ">
          <CartButton/>
        </div>         
      
        <button
          id="btn-menu"
          className="navbar-toggler" 
          type="button" 
          data-toggle="collapse" 
          data-target=".multi-collapse" 
          aria-controls="navbarNavAltMarkup" 
          aria-expanded="false" 
          aria-label="Toggle navigation"
          >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse multi-collapse navbar-collapse w-100" id="navbarNavAltMarkup">
          <div className="navbar-nav mr-auto w-100">
            <div className="nav-nav-fill nav-item w-100">
              <NavigationBar />
            </div>
          </div>
          <div className="navbar-nav">
            <div className="nav-nav-right nav-item">     
              {feathersStore.isAuthenticated && feathersStore.user?.firstname === "default" && 
                <div className="nav-SignIn">
                  <SignInButton onClickSignIn={onClickSignIn}/>
              </div> } 
            </div> 
            <div className="nav-nav-right nav-item">     
              {feathersStore.isAuthenticated && feathersStore.user?.firstname !== "default" && 
                <div className="nav-SignIn">
                <AccountButton/>
                </div>}
            </div>
            {feathersStore.isAuthenticated && feathersStore.user?.firstname !== "default" &&   
              <div className="nav-cart-button-container nav-item ">     
                <WishListButton/> 
              </div>
            }          
            <div className="nav-cart-button-container nav-item ">
              <CartButton/>
            </div>            
            <Link to="/checkout" className="checkout-button-container nav-item ">
              TAMEIO
            </Link>
            <div className="nav-item dropdown mt-1 dropleft">
              <a className="nav-link dropdown-toggle language-styling text-white" 
                href="#" id="navbarDropdown" 
                role="button" 
                data-toggle="dropdown" 
                aria-haspopup="true" 
                aria-expanded="false">
                {feathersStore.language === "en" ?
                  <img src={require('../../img/languages/language-1.png')}  alt="..."/> :
                  <img src={require('../../img/languages/language-2.png')}  alt="..."/>
                }
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a className="dropdown-item" href="#" onClick={() => toggleLanguage("el")}>
                  <span>
                    <img src={require('../../img/languages/language-2.png')}  alt="..."/>
                  </span>
                  Ελληνικά</a>
                <a className="dropdown-item" href="#" onClick={() => toggleLanguage("en")} >
                  <span>
                    <img src={require('../../img/languages/language-1.png')}  alt="..."/>
                  </span>
                  English</a>                
              </div>
            </div> 
          </div>      
        </div>
      </nav>
    </div>
  )
}

export default inject('feathersStore')(observer(TopBar));