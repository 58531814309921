import React, {useState, useEffect} from 'react';
import Modal from 'react-awesome-modal';
import {
    FormControl,
    FormHelperText,
    Input, 
    Button, 
    CircularProgress,
    InputAdornment,
    IconButton 
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import FacebookIcon from '@material-ui/icons/Facebook';
import GreenSubmitButton from '../buttons/greenSubmitButton';
import AppColors from '../assets/colors';
import { inject, observer } from "mobx-react";
import Utils from '../../Utils'; 
import { useTranslation } from 'react-i18next';


let emailElement;
let passwordElement;
let firstNameElement;
let lastNameElement;
let phoneElement;


const RegistrationCard = ({visible, onClickAway, onClick, close, feathersStore})=> {

  const { t, i18n } = useTranslation('common');

  const [email, setEmail] = useState(""); 
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState(""); 
  const [lastName, setLastName] = useState(""); 
  const [phone, setPhone] = useState("");  
  const [showPassword, setShowPassword] = useState(false); 
  const [emailError, setEmailError] = useState(false); 
  const [passwordError, setPasswordError] = useState(false);  
  const [firstNameError, setFirstNameError] = useState(false); 
  const [lastNameError, setLastNameError] = useState(false); 
  const [phoneError, setPhoneError] = useState(false); 
  const [secondTime, setSecondTime] = useState(false);
    
  useEffect(() => {
      visible && firstNameElement.focus();
  }, [visible]);

  useEffect(()=> {
    i18n.changeLanguage(feathersStore.language);    
  },[feathersStore.language]);

  const firstNameChange = event => {    
    setFirstName(event.target.value);
    secondTime && firstNameValidation(event.target.value);   
  };

  const lastNameChange = event => {    
    setLastName(event.target.value);
    secondTime && lastNameValidation(event.target.value);   
  };

  const phoneChange = event => {    
    setPhone(event.target.value);
    secondTime && phoneValidation(event.target.value);   
  };

  const emailChange = event => {    
    setEmail(event.target.value);
    secondTime && emailValidation(event.target.value);   
  };

  const passwordChange = event => {    
    setPassword(event.target.value);
    passwordValidation(event.target.value);   
  };

  const firstNameValidation = val => {
    if (!Utils.validateName(val) ) {
      setFirstNameError(true);
    }else{
     setFirstNameError(false);
   }
  }

  const lastNameValidation = val => {
    if (!Utils.validateName(val) ) {
      setLastNameError(true);
    }else{
     setLastNameError(false);
   }
  }

  const phoneValidation = val => {
    if (!Utils.validatePhone(val) ) {
      setPhoneError(true);
    }else{
     setPhoneError(false);
   }
  }

  const emailValidation = val => {
     if (!Utils.validateEmail(val) ) {
      setEmailError(true);
    }else{
      setEmailError(false);
    }
  } 

  const passwordValidation = val => {
    if (!Utils.validatePassword(val) ) {
     setPasswordError(true);
   }else{
     setPasswordError(false);
   }
 } 

  const passwordFocus = () => { 
    setSecondTime(true);   
  };

  const enterFirstNamePressed = (ev) => {   
    if (ev.key === 'Enter') {
        lastNameElement.focus();
    }
  }

  const enterLastNamePressed = (ev) => {   
    if (ev.key === 'Enter') {
        emailElement.focus();
    }
  }

  const enterEmailPressed = (ev) => {   
    if (ev.key === 'Enter') {
        phoneElement.focus();
    }
  }

  const enterPhonePressed = (ev) => {   
    if (ev.key === 'Enter') {
        passwordElement.focus();
    }
  }

  const submitEditing = async(ev) => {
    if (ev.key === 'Enter') {
      createAccount();
    }
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const createAccount = () => {
    feathersStore.sendApplication( firstName, lastName, email, phone,  password)     
    .then(async(application) =>{
      await feathersStore.logout();   
    onClick({
              applicationId: application._id,
              email,
              firstName,
              lastName,
              phone,
              password
        }); 
    });
  };

  const handleExit = () => {
    setSecondTime(false);
    setEmailError(false);
    setPasswordError(false);
    setEmail('');
    setPassword('');
    close();
  }

  return (
      <section>
        <Modal visible={visible} width="600" height="600" effect="fadeInUp" onClickAway={onClickAway}>
          <div className="modalContainer">
            <div className="modalTitleContainer">
              <h1>{t("common:register")}</h1>
            </div>
          <div className="modalRegisterFormContainer">
            <FormControl fullWidth={true}> 
              <Input
                value={firstName}     
                error={firstNameError}
                inputRef={r => {
                  firstNameElement = r;
                }}
                onChange={firstNameChange}              
                onKeyPress={enterFirstNamePressed}                
                placeholder={t("common:firstName")+"*"}
              />
              {firstNameError && <FormHelperText id="component-error-text" >{t("common:firstNameError")}</FormHelperText>}
            </FormControl>
            <FormControl fullWidth={true}>
              <Input
                value={lastName}     
                error={lastNameError}
                inputRef={r => {
                  lastNameElement = r;
                }}
                onChange={lastNameChange}              
                onKeyPress={enterLastNamePressed}  
                placeholder={t("common:lastName")+"*"}
              />
              {lastNameError && <FormHelperText id="component-error-text" >{t("common:lastNameError")}</FormHelperText>}
            </FormControl>
            <FormControl fullWidth={true}> 
              <Input
                value={email}     
                error={emailError}
                inputRef={r => {
                   emailElement = r;
                }}
                onChange={emailChange}              
                onKeyPress={enterEmailPressed}   
                placeholder={t("common:email")+"*"}
              />
              {emailError && <FormHelperText id="component-error-text" >{t("common:emailError")}</FormHelperText>}
            </FormControl>
            <FormControl fullWidth={true}>    
              <Input
                 value={phone}     
                 error={phoneError}
                 inputRef={r => {
                    phoneElement = r;
                 }}
                 onChange={phoneChange}              
                 onKeyPress={enterPhonePressed}   
                placeholder={t("common:phone")+"*"}
              />
              {phoneError && <FormHelperText id="component-error-text" >{t("common:phoneError")}</FormHelperText>}
            </FormControl>
            <FormControl fullWidth={true}>
            <Input
              value={password}
              error={passwordError}              
              inputRef={r => {
                passwordElement = r;
              }}             
              type={showPassword ? 'text' : 'password'}
              onChange={passwordChange}
              onFocus={passwordFocus}          
              onKeyPress={submitEditing}              
              placeholder="Password*" 
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
             {passwordError && 
              <FormHelperText id="component-error-text" color="secondary">{t("common:password-helper-text")}</FormHelperText>}
            </FormControl>
            <GreenSubmitButton
              title={t("common:register-a")}
              onClick={createAccount}
             />
          </div>
          <div className="maybe">
            <div className="OR">
              <hr className="hr"/>
            </div>
            <a style={{margin: 5}}>{t("common:or")}</a>
            <div className="OR">
              <hr className="hr"/>
            </div>
          </div>
          <div className="modalFacebookContainer">
          <Button variant="outlined" color="primary">
              <FacebookIcon style={{marginRight: 5}}/>
              {t("common:facebook-login")}</Button>
          </div>
          <div className="modalExitButtonContainer">
              <p style={{fontSize: 12}}>{t("common:register-info-a")} <a href={'#'}>{t("common:register-info-b")}</a>, {t("common:register-info-c")} <a href={'#'}>{t("common:register-info-d")}</a> {t("common:register-info-e")} <a href={'#'}>{t("common:register-info-f")}</a>.</p>
            <Button 
              style={{color: AppColors.red, fontSize: 18}} 
              onClick={handleExit}
            >{t("common:exit")}</Button>
          </div>
          </div>
        </Modal>
      </section>
  );
}

export default inject('feathersStore')(observer(RegistrationCard));
