import React from 'react'
import LocalShipping from '@material-ui/icons/LocalShipping'
import Payment from '@material-ui/icons/Payment'
import Colors from '../../assets/colors';
import "./SiteOffers.css";

export const FreeShipping = ({ header, body })=> {

    return(
        <div className="offer-box">
            <LocalShipping style={{fontSize: 40, color: Colors.primary}}/>
            <p className="offers-title">{header}</p>
            <p className="offers-text">{body}</p>
        </div>
    )
}

export const CashOnDelivery = ({ header, body })=> {

    return(
        <div className="offer-box">
            <Payment style={{fontSize: 40, color: Colors.primary}}/>
            <p className="offers-title">{header}</p>
            <p className="offers-text">{body}</p>
        </div>
    )
}