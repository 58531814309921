import React, {useState, useEffect} from 'react';
//import Modal from 'react-awesome-modal';
import {
  FormControl,
  FormHelperText,
  Input,
  TextareaAutosize,
  Modal
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseButton from '../../buttons/closeButton'
import GreenSubmitButton from '../../buttons/greenSubmitButton';
import { easings } from 'react-animation'
import RoundedButton from '../../buttons/roundedButton'
import HomeIcon from '@material-ui/icons/Home';
import ApartmentIcon from '@material-ui/icons/Apartment';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import { inject, observer } from "mobx-react";
import Utils from '../../../Utils'; 
import "./AddOrEditAddressModal.css";
import { useTranslation } from 'react-i18next';

let streetElement;
let streetNumberElement;
let cityElement;
let postcodeElement;
let apartmentElement;
let addressCommentElement;

function getModalStyle() {
  const top = 50;
  const left = 50 ;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
        
    backgroundColor: theme.palette.background.paper,
  //  border: '2px solid #000',
    boxShadow: theme.shadows[5],
  //  padding: theme.spacing(2, 4, 3),
  },
}));

const AddOrEditaddressModal = ({visible, close, itemToEdit, feathersStore})=> { 

  const { t, i18n } = useTranslation(['common','account']);

  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  
  const [otherSelected, setOther] = useState(false);
  const [homeSelected, setHome] = useState(false);
  const [workSelected, setWork] = useState(false);
  const [header, setHeader] = useState("");
  const [title, setTitle] = useState("Οικία");
  const [streetNumber, setStreetNumber] = useState("");
  const [street, setStreet] = useState("");
  const [apartment, setApartment] = useState(""); 
  const [addressComment, setAddressComment] = useState(""); 
  const [postcode, setPostcode] = useState(""); 
  const [city, setCity] = useState("");  
  const [active, setActive] = useState(false); 
  const [streetError, setStreetError] = useState(false);
  const [streetNumberError, setStreetNumberError] = useState(false);  
  const [cityError, setCityError] = useState(false);
  const [loading, setLoading] = useState(false); 

  
  useEffect(() => {
    i18n.changeLanguage(feathersStore.language); 
    setHeader(t("account:add-new-address"));
    if(Object.keys(itemToEdit).length !== 0 && itemToEdit.constructor === Object){
      setHeader(t("account:modal-header-update"));
      setTitle(itemToEdit.title);
      switch(itemToEdit.title){
        case "Οικία" : setHome(true);
        break;
        case "Έργασία" : setWork(true);
        break;
        case "Άλλη Τοποθεσία" : setOther(true);
        break;
      }
      setStreet(itemToEdit.street);
      setStreetNumber(itemToEdit.streetNumber);
      setCity(itemToEdit.city);
      setPostcode(itemToEdit.postcode);
      setApartment(itemToEdit.apartment);
      setAddressComment(itemToEdit.addressComment);
      setActive(itemToEdit.active);
    }   
  }, [feathersStore.language])

  const PressOther = ()=> {
    setOther(true);
    setHome(false);
    setWork(false);
    setTitle('Άλλη Τοποθεσία')
  }

  const PressHome = ()=> {
    setOther(false);
    setHome(true);
    setWork(false);
    setTitle('Οικία')
  }

  const PressWork = ()=> {
    setOther(false);
    setHome(false);
    setWork(true);
    setTitle('Έργασία');
  }

  const onChangeText = key => (event) => {
    
    switch(key){
      case "street" : {setStreet(event.target.value); streetValidation(event.target.value);}
      break;
      case "streetNumber" : {setStreetNumber(event.target.value); streetNumberValidation(event.target.value);}
      break;
      case "city" : {setCity(event.target.value); cityValidation(event.target.value);}
      break;
      case "postcode" : setPostcode(event.target.value); 
      break;
      case "apartment" : setApartment(event.target.value);
      break;
      case "addressComment" : setAddressComment(event.target.value);
      break;
    }
  };

  const enterPressed = key => (ev) => {   
    if (ev.key === 'Enter') {
      switch(key){
        case "street" : streetNumberElement.focus();
        break;
        case "streetNumber" : cityElement.focus();
        break;
        case "city" : postcodeElement.focus();
        break;
        case "postcode" : apartmentElement.focus();
        break;
        case "apartment" : addressCommentElement.focus();
        break;
        case "addressComment" : streetElement.focus();
        break;
      }
        
    }
  }

  const streetValidation = val => {
    if (!Utils.validateNonEmpty(val) ) {
      setStreetError(true);
    }else{
     setStreetError(false);
   }
  }

  const streetNumberValidation = val => {
    if (!Utils.validateNonEmpty(val) ) {      
      setStreetNumberError(true);
    }else{
     setStreetNumberError(false);
   }
  }

  const cityValidation = val => {
    if (!Utils.validateNonEmpty(val) ) {
      setCityError(true);
    }else{
     setCityError(false);
   }
  }

  const saveAddress = async () => {
    setLoading(true);  
    let clonedAddresses=[...feathersStore.user?.addresses];         
    const data = { title, city, street,  streetNumber, postcode, apartment, addressComment, active};        
    if(Object.keys(itemToEdit).length !== 0 && itemToEdit.constructor === Object){
      const index = clonedAddresses.indexOf(itemToEdit);
      clonedAddresses.splice(index, 1, data);
    }else {
      clonedAddresses.push(data);     
    }      
    try{
      const user = await feathersStore.patchAddresses( feathersStore.user._id, [...clonedAddresses]);
      feathersStore.setUser(user);
      setLoading(false);   
      alert(t("common:update-success"));
    }catch (error){
      setLoading(false);   
      alert(t("common:update-error") + `:  ${error.message}`)
    }
  };

  return(
    <section>    
    <Modal
        open={visible}
        onClose={close}
        aria-labelledby="address-edit-modal"
        aria-describedby="modal-for-editing-an-address"
    >
    <div style={modalStyle} className={classes.paper}>
    <div className="add-address-container">
      <div className="address-modal-header">
        <div className="edit-address-title">
          <h5>{header}</h5>
        </div>
        <div className="edit-address-close-button">
          <CloseButton onClick={close}/> 
        </div>          
      </div>      
      <div className="addNewAddressButtonsContainer">
        <div className="typeAddressButton">
          <RoundedButton
          color={otherSelected? 'green' : '#ebebeb'}
          icon={<ApartmentIcon style={{color: otherSelected? '#fff' : 'gray'}}/>}
          onClick={PressOther}
          />
          <a style={{color: 'grey', fontSize: 12, marginTop: 5}}>{t("account:other-location")}</a>
        </div>
        <div className="typeAddressButton">
          <RoundedButton
          color={homeSelected? 'green' : '#ebebeb'}
          icon={<HomeIcon style={{color: homeSelected? '#fff' : 'gray'}}/>}
          onClick={PressHome}
          />
          <a style={{color: 'grey', fontSize: 12, marginTop: 5}}>{t("account:home")}</a>
        </div>
        <div className="typeAddressButton">
          <RoundedButton
          color={workSelected? 'green' : '#ebebeb'}
          icon={<BusinessCenterIcon style={{color: workSelected? '#fff' : 'gray'}}/>}
          onClick={PressWork}
          />
          <a style={{color: 'grey', fontSize: 12, marginTop: 5}}>{t("account:work")}</a>
        </div>
      </div>
      <a style={{color: 'grey'}}>{t("account:helper-text")}</a>
      <FormControl fullWidth={false}> 
        <Input
          value={street}     
          error={streetError}
          inputRef={r => {
            streetElement = r;
          }}
          onChange={onChangeText("street")}              
          onKeyPress={enterPressed("street")}                
          placeholder={t("common:street") + "*"}
        />
        {streetError && <FormHelperText id="component-error-text" >{t("common:street-error")}</FormHelperText>}
      </FormControl>
      <FormControl fullWidth={false}> 
        <Input
          value={streetNumber}     
          error={streetNumberError}
          inputRef={r => {
            streetNumberElement = r;
          }}
          onChange={onChangeText("streetNumber")}              
          onKeyPress={enterPressed("streetNumber")}                
          placeholder={t("common:streetNumber") + "*"}
        />
        {streetNumberError && <FormHelperText id="component-error-text" >{t("common:streetNumber-error")}</FormHelperText>}
      </FormControl>        
      <FormControl fullWidth={false}> 
        <Input
          value={city}     
          error={cityError}
          inputRef={r => {
            cityElement = r;
          }}
          onChange={onChangeText("city")}              
          onKeyPress={enterPressed("city")}                
          placeholder={t("common:city") + "*"}
        />
        {cityError && <FormHelperText id="component-error-text" >{t("common:city-error")}</FormHelperText>}
      </FormControl>
      <FormControl fullWidth={false}> 
        <Input
          value={postcode}         
          inputRef={r => {
            postcodeElement = r;
          }}
          onChange={onChangeText("postcode")}              
          onKeyPress={enterPressed("postcode")}                
          placeholder={t("common:postcode") + "*"}
        />       
      </FormControl>
      <FormControl fullWidth={false}> 
        <Input
          value={apartment}          
          inputRef={r => {
            apartmentElement = r;
          }}
          onChange={onChangeText("apartment")}              
          onKeyPress={enterPressed("apartment")}                
          placeholder={t("common:apartment")}
        />        
      </FormControl> 
      <FormControl style={{width: '80%'}}> 
        <TextareaAutosize
          rowsMax="3"
          rowsMin="3"
          value={addressComment}          
          ref={r => {
            addressCommentElement = r;
          }}
          onChange={onChangeText("addressComment")}              
          onKeyPress={enterPressed("addressComment")}                
          placeholder={t("account:address-comments")}
        />        
      </FormControl>       
      <GreenSubmitButton         
        title={t("common:save")}
        loading={loading}
        disabled={streetError || streetNumberError || cityError} 
        onClick={saveAddress}  
      /> 
    </div>
    </div>
    </Modal>
  </section>
  )
}

export default inject('feathersStore')(observer(AddOrEditaddressModal));