import React from 'react'
import {Link} from 'react-router-dom'

const AdministrativeDashboard = ()=> {

    return(
        <div>
            <h1>Dashboard</h1>
            <Link to="/">
                <p>Back</p>
            </Link>
        </div>
        
    )
}

export default AdministrativeDashboard;