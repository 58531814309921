import React, {useState, useEffect} from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import AddressComponent from '../cards/addressComponent';
import AddIcon from '@material-ui/icons/Add';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import HomeIcon from '@material-ui/icons/Home';
import CloseIcon from '@material-ui/icons/Close';
import RoundedButton from '../buttons/roundedButton';
import { inject, observer } from "mobx-react";
import AddOrEditaddressModal from '../modals/addOrEditAddressModal/AddOrEditAddressModal';
import { useTranslation } from 'react-i18next';

const Address = ({onClickClose, onClickAddNew, onClickLocation, feathersStore}) => {

  const { t, i18n } = useTranslation('account');

  const [addresses, setAddresses] = useState([]);  
  const [showButtons, setShowButtons] = useState(false);
  const [visibleAddNewAddress, setAddNewAddress] = useState(false);

  useEffect(()=> {
    i18n.changeLanguage(feathersStore.language);    
  },[feathersStore.language]); 

  useEffect(() => {
    feathersStore.user?.addresses && setAddresses([...feathersStore.user?.addresses]);
  }, [feathersStore.user?.addresses])

  const openNewAddress = () => {     
    setAddNewAddress(true); 
  };

  const closeNewAddress = () =>{
    setAddNewAddress(false); 
  }

  const ClickAddAddress = ()=> {
      setShowButtons(!showButtons);
  }

  const setDeliveryAddress = item => () => {    
    const index = addresses.indexOf(item);
    const activeIndex = addresses.findIndex(e => e.active === true);
    let clonedAddresses = [...addresses];

    if (activeIndex !== index) {
      clonedAddresses[activeIndex].active = false;
      clonedAddresses[index].active = true;      
      updateUser(clonedAddresses);   
    }
  };

  const updateUser = async newAddresses => {
    setAddresses([...newAddresses]); 
    let clonedUser = JSON.parse(JSON.stringify(feathersStore.user));
    clonedUser.addresses = [...newAddresses];
    feathersStore.setUser(clonedUser); 
    await saveActiveAddress(newAddresses); 
  }

  const saveActiveAddress = async val => {   
    await feathersStore.patchAddresses( feathersStore.user._id, [...val]);   
  };
   
  const renderAddressItem = ( item, index ) => (
   
    <AddressComponent
      key={index}
      onPress={setDeliveryAddress(item)}     
      title={item.title === "Οικία" ? t("account:home") : item.title === "Έργασία" ? t("account:work") : t("account:other-location")}    
      street={item.street}
      city={item.city}
      apartment={item.apartment}
      postcode={item.postcode}
      streetNumber={item.streetNumber}
      active={item.active} 
      item={item}    
    />
    );
    
  return (
    <div className="history-container">
    <div className="history-boject-container"> 
      <FormControl component="fieldset" style={{width: '100%'}}>
        <FormLabel component="legend"/>
        <RadioGroup aria-label="addresses" name="addresses"  onChange={setDeliveryAddress}>
          {addresses.length !=0 ? addresses.map(renderAddressItem) 
          : <h3 style={{color: 'grey'}}>{t("account:add-address")}</h3> }
        </RadioGroup>
      </FormControl>
      </div>
      <div className="addressAddContainer">
        {showButtons &&
        <div className="addAddressOptions">
          <div className="addAddressOptionsButton">
            <div className="addAddressOptionsButton-label">{t("account:add-new-address")}</div>
            <RoundedButton
              color={'#00b970'}
              icon={<HomeIcon style={{color: '#fff', fontSize: 30, marginTop: 2}}/>}
              onClick={openNewAddress}
            />
          </div>
        
          <div className="addAddressOptionsButton">
            <div className="addAddressOptionsButton-label">{t("account:my-location")}</div>
            <RoundedButton
              color={'#ffa400'}
              icon={<LocationOnIcon style={{color: '#fff', fontSize: 30, marginTop: 2}}/>}
              onClick={onClickLocation}
            />
          </div>
        </div>}
        <RoundedButton
          color={'#0069b9'}
          icon={showButtons?
            <CloseIcon style={{color: '#fff', fontSize: 30, marginTop: 2}}/>
            :
            <AddIcon style={{color: '#fff', fontSize: 30, marginTop: 2}}/>}
          onClick={ClickAddAddress}
        />
      </div> 
      <AddOrEditaddressModal visible={visibleAddNewAddress} close={closeNewAddress} itemToEdit={{}}/>   
    </div>

  
  );
}

export default inject('feathersStore')(observer(Address));
