import React from 'react';
import Modal from 'react-awesome-modal';
import Button from '@material-ui/core/Button';
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';
import AppColors from '../assets/colors'


const OverStockWarning = ({visible, onClick})=> {
    const useStyles = makeStyles((theme) => ({
        margin: {
          margin: theme.spacing(1),
        },
      }));
      
      const theme = createMuiTheme({
        palette: {
          primary: red,
          secondary: green,
        },
      });
    const classes = useStyles();
    
        return (
            <section>
                <Modal visible={visible} width="350" height="160" effect="fadeInUp" >
                    <div className="modalContainer">
                    <div className="modalTitleContainer">
                    <p style={{color: 'red', marginTop: 10}}>Προσοχή!</p>
                    <p style={{color: 'grey'}}>Δεν μπορείτε να προσθέσετε περισσότερα τεμμάχια στο καλάθι σας από το απόθεμα!</p>
                    </div>
                    <div className="confirmationModalButtonsView">
                     <Button 
                    style={{color: AppColors.secondary, fontSize: 18}} 
                    onClick={onClick}
                    >OK</Button>
                    </div>
                    </div>
                </Modal>
            </section>
        );
}

export default OverStockWarning;