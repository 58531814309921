import React, { useEffect } from 'react';
import Colors from '../assets/colors'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { inject, observer } from "mobx-react";

const HistoryComponent = ({date, list, total, onClick, status, orderNumber, feathersStore})=> {
  
  const { t, i18n } = useTranslation('common');

  useEffect(()=> {
    i18n.changeLanguage(feathersStore.language);    
  },[feathersStore.language]);

  const renderListItems = list.map((item, index)=> {
    return(
        
      <div key={index} className="history-object-body">
        <Link to="/product">
          <button onClick={()=> item.enabled ? onClick(item.product_id) : {}} className="history-object-link">
            <p style={item.enabled ? {color: 'grey'} : { color : 'red' }}>{item.title} x {item.quantity}</p></button>
        </Link>
        <p style={item.enabled ? {color: 'grey'} : { color : 'red' }}>{item.totalPrice}€</p>
      </div>
    )
  })

  return (
    <div className="history-object">
      <div className="history-object-topHeader">
        <p style={{color: Colors.primary}}>{t("common:order")} #{orderNumber}&nbsp;{date}</p>
      </div>
      <div className="history-object-header">
        <p>{t("common:product")}</p>
        <p>{t("common:sub-total")}</p>
      </div>
      <div className="history-object-body-container">
        {renderListItems}
      </div>
      <div className="history-object-footer">
        <p style={{fontWeight: 'bold'}}>{t("common:total")}:</p>
        <p>{total}€</p>
      </div>
    </div>
  )
}

export default inject('feathersStore')(observer(HistoryComponent));