import React from 'react'
import Button from 'react-bootstrap/Button';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import AppColors from '../assets/colors'

const Camera = (props)=> {

  function changeCursor(e) {
    e.target.style.cursor = 'pointer';
  }

  return(
    <div className="changeProfilePicContainer">
      <Button onMouseOver={changeCursor} onClick={props.onClick} className="changeProfilePic">
        <PhotoCameraIcon style={{color: AppColors.menuTitles, fontSize: 16}}/>
      </Button>
    </div>
      
  )
}

export default Camera;