import React,{ useState } from 'react';
import AboutPic from '../img/AboutUs.jpg'
import CategoryPic from '../cards/categoryPic';
import Colors from '../assets/colors'


const About = ()=> {
    
    return(
        <div className="body">
            <CategoryPic image={AboutPic}/>           
            <div className="categoryView">

            </div> 
        </div>
    )
}

export default About;