import React from 'react'
import FavoriteBorder from '@material-ui/icons/FavoriteBorder'

const AddToWishListButton = ({ onClick, favorite })=> {

  return(
    <button 
      className="wishList-btn"
      onClick={onClick}
      style={{backgroundColor: favorite ? '#a8dadc':'#e63946'}}
    >
      <p>{favorite ? 'WishList Remove' : 'WishList Add'  }</p>
      <FavoriteBorder/>
    </button>
  )
}

export default AddToWishListButton;