import React from 'react';
import '../src/styles/style.css'
import Main from '../src/screens/main'
import feathersStore from './feathersStore';
import { Provider } from 'mobx-react';
import 'fontsource-roboto';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {

  return (
    <>
    <Provider feathersStore={feathersStore}>       
      <Main/>    
    </Provider> 
    </>
  );
}

export default App;
