import React from 'react'
import Close from '@material-ui/icons/Close';
import "./CartObject.css"

const CartObject = ({ item, onChange, onClickDelete, goToProduct}) => (
  <div className="cart-object">
    <div className="cart-icons">
      <div>
        <button className="close-button bg-transparent" onClick={onClickDelete}>
          <Close style={{color: 'red'}}/>
        </button> 
      </div>
      <div onClick={goToProduct}>
        <img alt="" style={{width: 60, height: 50}} src={`https://ffi.appdate.gr/images/${item.image?.name}`} />
      </div>       
    </div>
    <div className="item-element-container">
      <div className="item-element-title">
        Προϊόν:
      </div>
      <button className="link-button bg-transparent" onClick={goToProduct}>
        {item.title}{item.variation && ("-" + item.variation) }
      </button>
    </div>
    <div className="item-element-container">
      <div className="item-element-title">
        Τιμή:
      </div>
     {item.price} €
    </div>
    <div className="item-element-container">
      <div className="item-element-title">
        Ποσότητα:
      </div>
      <input  onChange={ e => onChange(e)} onKeyDown={(event) => {event.preventDefault();}} 
        type="number" min="1" max={item.handleStock ? item.stock : null} defaultValue={item.quantity} 
        style={{width: 50, paddingLeft: 5}}/>
    </div>
    <div className="item-element-container">
      <div className="item-element-title">
        Υποσύνολο:
      </div>
      {item.totalPrice} €
    </div>    
  </div>
  )

export default CartObject;