import React, {useState, useEffect} from 'react';
import Modal from 'react-awesome-modal';
import { LoopCircleLoading } from 'react-loadingg';
import { inject, observer } from "mobx-react";

const LoadingOrderModal = ({visible, whenSent, whenError, feathersStore})=> {
  
  useEffect(() => {
   visible && sendOrder();
  }, [visible]);

  const sendOrder = async() => {
    const orderCreated = await feathersStore.createOrder(feathersStore.orderItem);   
    if(orderCreated){     
      emptyCart(); 
      feathersStore.setCartLength(0);
      whenSent();     
    }else{
      alert(
        'Προσοχή! Η αποστολή της παραγγελίας δεν ήταν επιτυχής. Παρακαλώ ελέγξτε την σύνδεσή σας.'       
      )   
      whenError();   
    }
  }

  const emptyCart = () => {
    const emptyItem = {
      'customerId': "",
      'bookingDate': "",
      'status': '',
      'items': [],
      'paymentMethod':"",
      'total': 0,
      'processed': false,
      'latitude': 0,
      'longitude': 0,
      'city': "",
      'street': "",
      'streetNumber': "",
      'apartment': "",
      'postcode': "",
      'addressComments':""
    }
    localStorage.setItem("fire_cart", JSON.stringify([]));
    feathersStore.setOrderItem(emptyItem);
  }
    
  return (
    <section>
      <Modal visible={visible} width="300" height="270" effect="fadeInUp" >
        <div className="modalContainer">
          <h3>Αποστολή Παραγγελίας</h3>
          <a>Παρακαλώ περιμένετε...</a>
          <div className="modalRegisterFormContainer">
              <LoopCircleLoading />
          </div>
        </div>
      </Modal>
    </section>
  );
}

export default inject('feathersStore')(observer(LoadingOrderModal));
