import React, {useState, useEffect} from 'react';
import { 
  FormControl,
  FormHelperText,
  Input, 
  Button
} from '@material-ui/core';
import GreenSubmitButton from '../buttons/greenSubmitButton';
import { easings } from 'react-animation';
import AppColors from '../assets/colors';
import Modal from 'react-awesome-modal';
import { inject, observer } from "mobx-react";
import Utils from '../../Utils'; 
import { useTranslation } from 'react-i18next';

let emailElement;

const ResetPassword = ({ visible, close, feathersStore })=> {

  const { t, i18n } = useTranslation('common');

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [loading, setLoading] = useState(false);
  
  useEffect(()=> {
    i18n.changeLanguage(feathersStore.language);    
  },[feathersStore.language]);

  useEffect(() => {
    visible && emailElement.focus();
  }, [visible])

  const emailChange = event => {    
    setEmail(event.target.value);
    emailValidation(event.target.value);   
  };

  const emailValidation = val => {
    if (!Utils.validateEmail(val) ) {
     setEmailError(true);
   }else{
     setEmailError(false);
   }
  } 

  const submitEditing = async(ev) => {
    if (ev.key === 'Enter') {
    email && !emailError && await resetPassword();
    }
  }

  const resetPassword = () => {  
    setLoading(true);      
    feathersStore.authAndGetUser(email)
      .then(async(user) =>{         
        const { _id, email, firstname, lastname } = user[0];         
        await feathersStore.sendEmail( _id, email, firstname, lastname );
        await feathersStore.logout(); 
        setLoading(false); 
        close();
      })
      .catch(err => {
        setLoading(false);
      })
  };

  const style = {
  animation: `pop-in ${easings.easeOutExpo} 1000ms forwards`
  }
    
  return(
    <section>
      <Modal visible={visible} width="600" height="600" effect="fadeInUp" >
        <div className="resetPasswordModal">
          <h3>{t("common:reset-password")}</h3>
          <div className="passwordPic"/>   
          <p style={{color: AppColors.secondary}}>{t("common:reset-password-info-a")}<br/>{t("common:reset-password-info-b")}</p>
          <FormControl style={{width: '80%'}}>
            <Input
              value={email}     
              error={emailError}
              inputRef={r => {
                emailElement = r;
              }}
              onChange={emailChange}              
              onKeyPress={submitEditing}
              placeholder="E-mail"
            />
            {emailError && <FormHelperText id="component-error-text" >{t("common:emailError")}</FormHelperText>}
          </FormControl>
          <GreenSubmitButton 
            title={t("common:reset-password")} 
            onClick={resetPassword}
            loading={loading}
            disabled = {!email || emailError}
          />
          <Button 
            style={{color: AppColors.red, fontSize: 18}} 
            onClick={close}
          >{t("common:exit")}</Button>
        </div>
      </Modal>
    </section>
  )
}

export default inject('feathersStore')(observer(ResetPassword));
