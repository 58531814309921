import React from 'react';
import Colors from '../assets/colors'
import { Link } from 'react-router-dom';
import { inject, observer } from "mobx-react";
import Favorite from '@material-ui/icons/Favorite';


const WishListButton = ({ feathersStore }) => {

  

  return ( 
    <Link to="/wishList" >
    <button type="button" className="cart-button" >
      <Favorite style={{color: "#fff"}}/>
      <span className="badge">{feathersStore.favoritesArray?.length || 0}</span>
    </button>
    </Link>
  )
}


export default inject('feathersStore')(observer(WishListButton));