import React, {useState, useEffect} from 'react';
import Modal from 'react-awesome-modal';
import GreenSubmitButton from '../buttons/greenSubmitButton';
import AppColors from '../assets/colors';

const OrderCompleteModal = ({visible, onClickFinish})=> { 
     
  return (
    <section>
      <Modal visible={visible} width="400" height="350" effect="fadeInUp" >
        <div className="modalContainer">
          <div className="orderInfoContainer-all">
            <div className="orderInfoContainer-OK"/>
            <h2>ΟΛΟΚΛΗΡΩΣΗ ΠΑΡΑΓΓΕΛΙΑΣ!</h2>
            <p style={{color: AppColors.secondary, marginBottom: 30}}>Η παραγγελία σας καταχωρήθηκε με επιτυχία.<br/>
            Ευχαριστούμε για την προτίμησή σας.</p>
            <GreenSubmitButton
              title={'ΕΠΙΣΤΡΟΦΗ ΣΤΟ ΚΑΤΑΣΤΗΜΑ'}
              onClick={onClickFinish}
            />
          </div>
        </div>
      </Modal>
    </section>
  );
}

export default OrderCompleteModal;