import React, {useState, useEffect} from 'react';
import "./Product.css"
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Colors from '../../components/assets/colors'
import NoneEvaluation from '../../components/screenComponents/noneEvaluation'
import RatingForm from '../../components/forms/ratingForm'
import RegisteredEmptyRating from '../../components/cards/registeredEmptyRating'
import Rating from '@material-ui/lab/Rating';
import RatedCard from '../../components/cards/ratedCard'
import UnregisteredEmptyRating from '../../components/cards/unregisteredEmptyRating'
import AddToWishListButton from '../../components/buttons/addToWishlistButton'
import MenuItem from '@material-ui/core/MenuItem';
import ProductCard from '../../components/cards/productCard/ProductCard';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import NavigateNext from '@material-ui/icons/NavigateNext'
import NavigateBefore from '@material-ui/icons/NavigateBefore'
import FormHelperText from '@material-ui/core/FormHelperText';
import { CSSTransition } from 'react-transition-group';
import {Alert} from 'react-bootstrap';
import { inject, observer } from "mobx-react";
import { useHistory, useParams } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';


let initialCartBiggestId = 0;
let cart = [];

const Product = ({ location, feathersStore })=> {

  const history = useHistory();
  const { id } = useParams(); 

  let paramProduct; 

  const [product, setProduct] = useState({});
  const [imageList, setImageList] = useState([]);
  const [randomProducts, setRandomProducts] = useState([]);
  const [variations, setVariations] = useState([])
  const [variation, setVariation] = useState({}); 
  const [rating, setRating] = useState(1);  
  const [quantity, setQuantity] = useState(1);
  const [warning, setWarning] = useState(false);
  const [favorite, setFavorite] = useState(false);   

  const [purchased, setPurchased] = useState(false);
  
  //Information Modal controls
  const [cartIncreased, setCartIncreased] = useState(false);
  const [favoritesUpdated, setFavoritesUpdated] = useState(false);
  
  useEffect(() => {
    if(localStorage.getItem('fire_cart')){
      cart = JSON.parse(localStorage.getItem('fire_cart'));    
      if(cart.length > 0)
        initialCartBiggestId = +cart.map(e => e.id).sort().reverse()[0] + 1;         
    }
  }, [location?.payload]);

  useEffect(() => {    
    feathersStore.isAuthenticated && initProduct();  
  }, [location?.payload, feathersStore.isAuthenticated, feathersStore?.favoritesArray]); 

  const initProduct = async() => {   
    paramProduct = location?.payload;    

    //USAGE: for reloads
    if(!location?.payload){     
      paramProduct = await feathersStore.getProduct(id);      
    }

    if(paramProduct?.hasVariation){       
      setVariations(paramProduct?.variations)
      setVariation(paramProduct?.variations[0])       
    } 
    checkFavorite(); 
    setImageList(getImageArr(paramProduct));   
    setProduct(paramProduct);   
  }    
  
  const getImageArr = product => {
    let imageArr = [];
    if(product.hasVariation){
      imageArr = product.variations
        .map(variation => variation.images
          .map(image => Object.assign(image, {variation: variation.name}))) //Add variation name to the image
        .flat();
    }else{
      imageArr = product.images              
    }  
    return imageArr;
  }

  const checkFavorite = () => {       
    if(feathersStore.favoritesArray.includes(paramProduct._id)){    
      setFavorite(true);   
    }
  }

  const onPressAddToFavorites = async () => {    
    let id = '';
    id = product._id;
    if(feathersStore.favoritesArray.includes(id)){
      const index = feathersStore.favoritesArray.indexOf(id);
      if (index > -1) {
        feathersStore.favoritesArray.splice(index, 1);
      }
      setFavorite(false);
    }else{
      feathersStore.favoritesArray.push(id);
      setFavorite(true);
    }   
    if(feathersStore.wishlistId === ""){
        const data = await feathersStore.createWishlist();        
        data && (feathersStore.wishlistId = data._id); 
    }else await feathersStore.updateWishlist();
    setFavoritesUpdated(true);
  };
   
  useEffect(()=>{
    feathersStore.isAuthenticated && fetchProducts();   
  },[feathersStore.isAuthenticated]);

  const fetchProducts = async() => {
    const feathersProducts = 
    await feathersStore.products();
    setRandomProducts([...feathersProducts]); 
  }

  const getMainImage = (product)=> {
    let list = getImageArr(product);
    const mainImg = list.find(img => img.focused);  
    return mainImg;          
  }

  const handlePress = (product)=> {
    history.push({
      pathname: `/product/${product._id}`,
      payload: product
    }); 
  }

  const getPrice = (product)=> {      
    if(product.hasVariation){
      let priceArr = [];
      priceArr = product.variations
        .map(variation => +variation.retailPrice)
        const low = Math.min(...priceArr)
        const high = Math.max(...priceArr)
        if(low === high){
            return high.toFixed(2)
        }else{
            return low.toFixed(2) + " - " + high.toFixed(2)
        }          
    }else{
        return (+product.retailPrice).toFixed(2);
    }
  }

  const getBadges = product => {    
    if(product.hasVariation){
      let vrtion = product.variations
        .find(variation => variation.images.map(image => image.focused));
      return vrtion.badges;
    }
    return product.badges;
  }

  const getOldPrice = product => {
    if(product.hasVariation){
      let vrtion = product.variations
        .find(variation => variation.images.map(image => image.focused));
      return vrtion.oldPrice;
    }
    return product.oldPrice;
  }

  const Menu = randomProducts.map(product => {
    
    return (
      <MenuItem key={product._id}>
        <ProductCard
          key={product._id}
          stock={product.handleStock && product.stock}
          image={getMainImage(product)}
          title={product.name}
          price={getPrice(product)}
          oldPrice={getOldPrice(product)}
          onClick={()=> handlePress(product)}
          onClickCart={() => handleAddToCart(product, true)}
          hasVariation={product.hasVariation}
          onClickForward={()=> handlePress(product)}
          badges={getBadges(product)}
        />
      </MenuItem>
    );
  });

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

  const classes = useStyles();

  const renderImages = imageList.map((image, index)=>
    (<div key={image.name} style={{display: 'inline-grid'}}>
      <button style={{backgroundColor: image.focused && Colors.primary}}  onClick={()=>selectImage(image)}>
        <img alt="" src={`https://ffi.appdate.gr/images/${image?.name}`}/>
      </button>
    </div>)
  );

  const renderMainImage = imageList.map((image, index)=>(   
    image.focused && <img className="hl-image" alt=""  key={image.name}
    src={`https://ffi.appdate.gr/images/${image.name}`} />
  ))   
        
  const selectImage = (image)=> {
    const index = imageList.indexOf(image)
    let newList = [...imageList]
    newList.forEach(item => item.focused = false)
    newList[index].focused = true
    setImageList([...newList]) 
    setVariation(variations.find(v => v.name === image.variation))
  }
 
  const handleChange = event => {
    let selectedVariation = event.target.value;    
    setVariation(selectedVariation); 
    setQuantity(1)    
    let newList = [...imageList]         
    if(selectedVariation.image === null){
      setSelected(true)
    }else{                
      newList.forEach(item => item.focused = false)
      const index = newList.findIndex(img => img.name === selectedVariation.images[0].name)
      console.log("index: ", index)
      newList[index].focused = true;
    } 
    setImageList([...newList]); 
  };

  const renderComments = product =>  product.commentsList.map( comment => 
    <RatedCard key={comment.id}
      username={comment.username}
      date={comment.date}
      value={comment.value}setVariationTitle
      comment={comment.text}
    />
  )     

  const ratingStars =  product => {
    const starsCount = product.commentsList?.map(comm => comm.value)
      .reduce((prev, next)=> prev + next, 0);    
    return starsCount / product.commentsList?.length;
  }
  
  const variationSelections = variations?.map((item, index) => (
      <MenuItem key={index} value={item}>{item.name}</MenuItem>
  ))

  //-------FEATURES LOGIC ----------------------------------------//
 
  const [featuresSelected, setFeaturesSelected] = useState([])
  const [featureSelectedName, setFeatureSelectedName] = useState('')
  const [error, setError] = useState(false)

  const renderFeatures = product => product.savedFeaturesTables?.map((table, index) => (
    <FormControl key={index} variant="outlined" className={classes.formControl} error={error}>
      <InputLabel id="demo-simple-select-outlined-label">{table.title}</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={featureSelectedName}
        onChange={(e)=> handleFeatures(e, table.title)}
        label={table.title}
      >
      {  table.savedFeaturesArray.map((i, index) => (
        <MenuItem disabled={i.active === false}            
          key={index}          
          value={i.name}>{i.name}</MenuItem>
      ))}            
      </Select>
      {featureSelectedName === '' && <FormHelperText style={{width: 'auto', color: '#000'}}>Κάντε μια επιλογή</FormHelperText>}
      {error && <FormHelperText>Κάντε μια επιλογή</FormHelperText>}
    </FormControl>
  ))


  const handleFeatures = (e, title)=> {
      let name = e.target.value
      let item = {
          title,
          name
      }
      const featuresSelectedClone = [...featuresSelected];
      const index = featuresSelectedClone.findIndex(val => val.title === title);
      index !== -1 ? featuresSelectedClone.splice(index, 1, item) : featuresSelectedClone.push(item);
      setFeatureSelectedName(name)
      setFeaturesSelected([...featuresSelectedClone]);
  } 

  const handleAddToCart = (prod, fromList) => { //fromList = prod is coming from the footer list and has no variations   
    let cartItem = {
      id: initialCartBiggestId,
      product_id: prod._id,
      title: prod?.name,
      image: fromList  ? prod.images?.find(img => img.focused) : imageList?.find(img => img.focused),
      price: prod.hasVariation ? variation?.retailPrice : prod?.retailPrice  ,
      quantity: fromList ? 1 : quantity,
      totalPrice: ((prod?.hasVariation ? +variation.retailPrice : +prod.retailPrice)*quantity).toFixed(2),
      variationTitle: prod?.variationTitle,
      variation: prod.hasVariation ? variation?.name : "",
      stock: prod.hasVariation ? variation?.handleStock && variation?.stock : prod?.handleStock && prod?.stock,
      extra: [...featuresSelected],
      handleStock: prod.hasVariation ? variation?.handleStock : prod?.handleStock
  }

  const cartSimilarItems = cart.filter(item => item.product_id === cartItem.product_id );
  let cartSimilarItem;  
  if(prod.hasVariation)cartSimilarItem = cartSimilarItems.find(sim => sim.variation === cartItem.variation);
  else cartSimilarItem = cartSimilarItems[0];
  if(cartSimilarItem){  
    const newQ = +cartSimilarItem.quantity + +cartItem.quantity;            
    if(prod.handleStock && (newQ > cartItem.stock)){
      setWarning(true)        
    }else{
      const cartIndex = cart.findIndex(item => item.id === cartSimilarItem.id);
      cartItem.quantity = newQ;
      cartItem.totalPrice = (cartItem.price * newQ).toFixed(2);
      cart.splice(cartIndex, 1, cartItem);
    }          
  }else{   
    cart.push(cartItem);
    feathersStore.setCartLength(feathersStore.cartLength + 1);      
  } 
    if(!warning){
      localStorage.setItem("fire_cart", JSON.stringify(cart));
      setCartIncreased(true);
    }
    !fromList && !warning && resetProduct();
  }

  const resetProduct = () => {
    history.push({
      pathname: `/product/${product._id}`,
      payload: product
    }); 
  }

  const setNewQuantity = (e)=> {
      let count = e.target.value;
      setQuantity(count)
  }
  
  const onCloseAlert = () => {
    setCartIncreased(false);
   }
   
  const [selected, setSelected] = useState(0)
  
  const ArrowLeft = <NavigateBefore style={{cursor: 'pointer', fontWeight: 'bold', fontSize: 50, color: 'grey'}}/>
  const ArrowRight = <NavigateNext style={{cursor: 'pointer', fontWeight: 'bold', fontSize: 50, color: 'grey'}}/>;

  return(
    <>
      <div className="product-mainBody">
        <div className="product-images-container">
          <div className="product-images">
            <div className="product-main-image" style={{position: 'relative'}}>
              {renderMainImage}
              {((product?.badges || variation?.badges)?.includes('new')) &&  (
                <div className="new-label-container">
                  <p className="product-label">NEW</p>
                </div>)
              }
              {(((product?.badges || variation?.badges)?.includes('sale')) || product?.oldPrice || variation?.oldPrice) &&  (
                <div className="discount-label-container">
                  <p className="product-label">SALE</p>
                </div>)
              }
              {((product?.badges || variation?.badges)?.includes('hot')) &&  (
                <div className="hot-label-container">
                  <p className="product-label">HOT</p>
                </div>)
              }     
            </div>
            <div className="product-collection-images">
              {renderImages}        
            </div>
          </div>
          </div>
          
          <div className="product-info">
            <div className="product-title">
              <h3>{product?.name}</h3>
            </div>
            <div className="productview-price">
              {feathersStore.isAuthenticated && feathersStore.user?.firstname !== "default" && 
                <AddToWishListButton 
                  onClick={onPressAddToFavorites}
                  favorite={favorite}
                />}
            </div>
            <div className="productview-price">              
                {(variation?.oldPrice || product?.oldPrice) &&
                  <h4  className="product-old-price">
                    <span>{((product?.hasVariation ? +variation.oldPrice : +product.oldPrice)*quantity).toFixed(2)} € </span>                   
                  </h4>
                }
                <h4>{((product?.hasVariation ? +variation.retailPrice : +product.retailPrice)*quantity).toFixed(2)} €</h4>
             
            </div>              
            <div className="html-text">
                {ReactHtmlParser(`<p>${product?.htmlContent}</p>`)} 
            </div>
           
              {product?.hasVariation &&  
                <div className="productVariation">
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="native-select">{product.variationTitle}</InputLabel>
                    <Select 
                      labelId="native-select"
                      value={variation}
                      onChange={handleChange}                  
                      id='ns'
                      label={product.variationTitle}               
                    >
                      {variations?.map((item, index) => (
                        <MenuItem key={index} value={item}>{item.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              }            
            
            {
              product.hasOtherFeatures && 
              <div className="productVariation" style={{marginBottom: 20}}>
                {product?.hasVariation ? renderFeatures(variation) : renderFeatures(product)}</div> 
            }

            {product?.handleStock && !product.hasVariation &&
              <div className="productStock">
                <div>{product?.stock !==0 ? <p>{product.stock} σε απόθεμα</p> : <p style={{color: 'red'}}>Εξαντλημένο</p>}</div>
              </div>
            }
              
            {product?.hasVariation && !variation && 
              <div className="productStock"><p style={{color: '#000'}}>Κάντε μια επιλογή</p></div>
            }

            {product?.hasVariation && variation?.handleStock && <div className="productStock">
              {variation.stock !== 0 ? <p>{variation.stock} σε απόθεμα</p> : <p style={{color: 'red'}}>Εξαντλημένο</p>}
            </div>}

            <CSSTransition
              in={warning}
              timeout={300}
              classNames="alert"
              unmountOnExit
            >
              <Alert variant="danger"
                dismissible
                onClose={() => setWarning(false)}>
                  <Alert.Heading>Προσοχή!</Alert.Heading> 
                  <p>Δεν μπορείτε να προσθέσετε περισσότερα τεμάχια στο καλάθι σας από το απόθεμα!</p>
              </Alert>
            </CSSTransition>   

            <div className="productAddToCart">
              <input value={quantity} onChange={setNewQuantity} type="number" min="1" style={{width: 50, paddingLeft: 5}}
                 max={product?.handleStock || variation?.handleStock ? product?.stock || variation?.stock : null} />           
              <button disabled={(product?.handleStock || variation?.handleStock) && 
                ((product?.stock || variation?.stock) - quantity === 0) || (product.hasOtherFeatures && !featureSelectedName) 
                || warning} 
                onClick={() => handleAddToCart(product, false)} className="btn btn-primary btn-sm" type="submit" >Προσθήκη στο καλάθι
              </button>
            </div>
          </div>        
      </div>

      <div className="container">       
        <div className="product-evaluation-title">
          <p>ΑΞΙΟΛΟΓΗΣΕΙΣ ({product.commentsList?.length}) / </p>
          {product.commentsList && 
            <Rating style={{marginLeft: 5, marginBottom: 15}} name="read-only" 
              value={ratingStars(product)} readOnly />
          }
        </div>        
        {feathersStore.isAuthenticated && 
          <div className="product-evaluation-body">
          { product.commentsList?.length === 0 && <NoneEvaluation/>}
          { feathersStore.user?.firstname === "default" || !purchased && <UnregisteredEmptyRating/> }
          { product.commentsList?.length === 0 && feathersStore.user?.firstname !== "default" && <RegisteredEmptyRating/>} 
          { product.commentsList && renderComments(product)}
        </div>
        }
          {purchased && feathersStore.isAuthenticated && feathersStore.user?.firstname !== "default" && 
          <RatingForm
            value={rating}
            onChange={(event, newValue) => {setRating(newValue);}}
          />}         
      </div>
      <div className="container">
        <h4>Δείτε ακόμα</h4>    
          {feathersStore.isAuthenticated &&
            <ScrollMenu
              data={Menu}
              arrowLeft={ArrowLeft}
              arrowRight={ArrowRight}
              selected={selected}
            />   
          }   
      </div>
      <div className="cart-updated-alert">
        <CSSTransition
          in={cartIncreased}
          timeout={300}
          classNames="alert"
          unmountOnExit
        >
          <Alert variant="info"
            dismissible
            onClose={onCloseAlert}>
              <Alert.Heading>Updated!</Alert.Heading> 
              <p>You updated succesfully your cart!</p>
          </Alert>
        </CSSTransition>
      </div>
      <div className="cart-updated-alert">
        <CSSTransition
          in={favoritesUpdated}
          timeout={300}
          classNames="alert"
          unmountOnExit
        >
          <Alert variant="info"
            dismissible
            onClose={() => setFavoritesUpdated(false)}>
              <Alert.Heading>Updated!</Alert.Heading> 
              <p>You updated succesfully your wishlist!</p>
          </Alert>
        </CSSTransition>
      </div>
   </>
  )
}

export default inject('feathersStore')(observer(Product));