import React,{useState, useEffect} from 'react'
import CartObject from './cartObject/CartObject';
import { useHistory } from 'react-router-dom';
import {Link} from 'react-router-dom'
import { inject, observer } from "mobx-react";
import ConfirmationModal from '../modals/confirmationModal';
import * as cloneDeep from 'lodash/cloneDeep';
import { useTranslation } from 'react-i18next';

const CartTable = ({feathersStore})=> {

  const history = useHistory();

  const { t, i18n } = useTranslation(['common', 'cart']);

  const [total, setTotal] = useState(0.0);
  const [totalQuantity, setTotalQuantity] = useState(0.0);
  const [cart_products, setCart_products] = useState([]);
  const [itemToDelete, setItemToDelete] = useState({});

  //Modal Controls
  const [visible, setVisible] = useState(false);  

  useEffect(() => {
    const cartFromStorage = JSON.parse(localStorage.getItem('fire_cart'))  
    if(cartFromStorage){
    setCart_products(cartFromStorage); 
    updateTotals(cartFromStorage);   
    }
  }, []);
  
  useEffect(()=> {
    i18n.changeLanguage(feathersStore.language);    
  },[feathersStore.language]);

const handleChangeQuantity = (e, item) => {    
  const newQ = e.target.value;
  const index = cart_products.indexOf(itemToDelete);
  let clonedCartProducts = [...cart_products];
  let productClone = cloneDeep(item);
  Object.assign(productClone, {quantity: newQ, totalPrice: (+item.price * +newQ).toFixed(2)});
  clonedCartProducts.splice(index, 1, productClone); 
  setCart_products(clonedCartProducts);  
  localStorage.setItem("fire_cart", JSON.stringify(clonedCartProducts));    
  updateTotals(clonedCartProducts);
}

   
  const removeItem = item => {
    setVisible(true);
    setItemToDelete(item)
  }

  const deleteItem = ()=> {
    const index = cart_products.indexOf(itemToDelete);
    let clonedCartProducts = [...cart_products];
    clonedCartProducts.splice(index, 1); 
    setCart_products(clonedCartProducts);     
    localStorage.setItem("fire_cart", JSON.stringify(clonedCartProducts));     
    updateTotals(clonedCartProducts);
    feathersStore.setCartLength(clonedCartProducts.length);    
    setVisible(!visible);
  }

  const updateTotals = cartProducts => {
    setTotal(cartProducts.map(element => +element.totalPrice)
      .reduce((t, sum) => sum + t, 0)); 
    setTotalQuantity(cartProducts.map(element => +element.quantity)
      .reduce((t, sum) => sum + t, 0));     
  };

  const closeConfirm = ()=> {
    setVisible(!visible);
  }

  const goToProduct = async (item) => {
    const index = cart_products.indexOf(item);        
    const prod = await feathersStore.getProduct(item.product_id);
    prod && history.push({
      pathname: `/product/${prod._id}`,
      payload: prod
    });
  };

  const renderCartProducts = cart_products.map((item, index) => (
    <CartObject
      key={index}
      item={item}             
      onChange={ e=> handleChangeQuantity(e, item)}      
      onClickDelete={()=> removeItem(item)}
      goToProduct={() => goToProduct(item)}
    />
  ))

  return(
  <>
    <>
      <ConfirmationModal 
        visible={visible}
        product={itemToDelete.name}
        onClickNo={closeConfirm}
        onClickYes={deleteItem}
      />     
    </>
    <div className="table-container">
      <div className="table">
        <div className="table-header">
          <div style={{flex: 3}}/>
          <div style={{flex: 2}}>
            <h5>{t("common:product")}</h5>
          </div>
          <div style={{flex: 2}}>
            <h5>{t("common:price")}</h5>
          </div>
          <div style={{flex: 2}}>
            <h5>{t("common:quantity")}</h5>
          </div>
          <div style={{flex: 2}}>
            <h5>{t("common:sub-total")}</h5>
          </div>   
        </div>
        <div className="table-body">
          {renderCartProducts}
        </div>
        <div className="table-footer">          
          <input type="text" placeholder={t("cart:coupon-code")} className="flex-1"/>
          <button className="btn btn-primary btn-sm flex-1" type="submit" >{t("cart:coupon-button")}</button>
        </div>
      </div>
    </div>
    <div className="cart-total-container">
      <div className="cart-total-table">
        <div className="cart-total-header">
          <h4>{t("cart:cart-totals")}</h4>
        </div>
        <div className="cart-total-body">
          <p>{t("common:products")}: <a style={{fontWeight: 'bold'}}>{feathersStore.cartLength}</a></p>
          <p>{t("common:items")}: <a style={{fontWeight: 'bold'}}>{totalQuantity}</a></p>
          <h5>{t("common:total")}: <a style={{fontWeight: 'bold'}}>{(+total).toFixed(2)} €</a></h5>
        </div>
        <div className="cart-total-footer">
          <Link to="/checkout">
            <button disabled={total === 0} className="btn btn-primary btn-mid" 
            type="submit" >{t("cart:complete-order")}</button>
          </Link>
        </div>
      </div>
    </div>
  </>
  )
}

export default inject('feathersStore')(observer(CartTable));