import { Payment } from '@material-ui/icons'
import React from 'react'

const PageTitle = ({title})=> {

    return(
    <div className="cart-title">
        <div>{title}</div>
    </div>
    )
}

export default PageTitle;