import React from 'react'
import Person from '@material-ui/icons/Person';


const SignInButton = ({onClickSignIn})=> {

    return(
        <button type="button" className="nav-button-SignIn" onClick={onClickSignIn}>
            <Person style={{fontSize: 30, color: '#fff'}}/>
           <span>SignIn</span>
        </button>
    )
}

export default SignInButton;