import React, { useEffect, useState } from 'react'
import HistoryComponent from '../cards/historyComponent';
import { useHistory } from 'react-router-dom';
import { inject, observer } from "mobx-react";
import { useTranslation } from 'react-i18next';


const History = ({ feathersStore })=> {

  const { t, i18n } = useTranslation('account');

  useEffect(()=> {
    i18n.changeLanguage(feathersStore.language);    
  },[feathersStore.language]);
  
  const history = useHistory();

  const [orders, setOrders] = useState(null);
  
  useEffect (() => {
    initOrders();
  }, []);

  const initOrders = async() => {
    let orders = await feathersStore.ordersPerUser();         
    setOrders(orders);
  }      

  const goToProduct = product_id => {   
   
    feathersStore.getProduct(product_id).then(prod => {     
      prod && history.push({
        pathname: `/product/${prod._id}`,
        payload: prod
      });
    });    
  }

  const renderItem = orders?.map(item=>      
    <HistoryComponent 
      key={item._id}
      orderNumber={item._id.slice(-6)}
      date={new Date(item.createdAt).toLocaleDateString()}
      list={item.items}
      total={item.total}
      onClick={goToProduct}
      status={item.status}
    />
  )

  return(
    <div className="history-container">
      <div className="history-boject-container">
      {orders?.length === 0 ? <h5>{t("account:history-placeholder")}</h5> : renderItem}
      </div>
    </div>
  )
}

export default inject('feathersStore')(observer(History));