import React from 'react'
import Button from 'react-bootstrap/Button';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';

const CloseButton = (props)=> {

    function changeCursor(e) {
        e.target.style.cursor = 'pointer';
      }

    return(
        <Button 
        className="closeButton"
        onMouseOver={changeCursor}
        onClick={props.onClick}>
            <CancelPresentationIcon style={{fontSize: 40, color: 'red'}}/>
        </Button>
    )
}

export default CloseButton;