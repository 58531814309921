import React, {useState, useEffect, useRef} from 'react';
import Colors from '../assets/colors';
import ListAltIcon from '@material-ui/icons/ListAlt';
import InfoIcon from '@material-ui/icons/Info';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import ExpandMore from '@material-ui/icons/ExpandMore'
import TermsNConditions from '../screenComponents/termsnconditions'
import About from '../screenComponents/about';
import LogoutComponent from './logoutComponent';
import { inject, observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';

const Settings = ({ feathersStore })=> {

  const { t, i18n } = useTranslation('common');

  const defaultEmail = "defaultUser@gmail.com";
  const defaultPassword = ")j~nKj/,N}N6,8&cVVV#G!=F*y";

  const [termsVisible, setTermsVisible] = useState(false);
  const [aboutVisible, setAboutVisible] = useState(false);
  const [logoutVisible, setLogoutVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const isMountedRef = useRef(null);

  const history = useHistory();

  useEffect(()=> {
    i18n.changeLanguage(feathersStore.language);    
  },[feathersStore.language]);

  useEffect(() => {
    isMountedRef.current = true; 
   // setAddresses()   
    return () => isMountedRef.current = false;
  }, [])

  const toggleTerms = () => {
    setTermsVisible(!termsVisible);
  }

  const toggleAbout = () => {
    setAboutVisible(!aboutVisible);
  }

  const toggleLogout = () => {
    setLogoutVisible(!logoutVisible);
  }

  const logout = async()=> {
    isMountedRef.current && setLoading(true);
    try{
        await feathersStore.logout();      
        feathersStore.login(defaultEmail, defaultPassword)
          .then(async(auth) => { 
            history.push('/') ; 
            await feathersStore.setObservables();                                 
        })
        isMountedRef.current && setLoading(false);
    }catch{
        console.log('ERROR LOGGING OUT');
        isMountedRef.current && setLoading(false);
    }   
  }   

  return(

    <div className="container">
      <div className="change-password">
        <div className="changePassTitle">
          <ListAltIcon style={{color: Colors.primary, fontSize: 30}}/>
          <h4 style={{color: Colors.primary}}>{t("common:terms-of-service-noun")}</h4>
        </div>
        {termsVisible ? 
        <button onClick={() => toggleTerms()}>
          <CancelPresentationIcon style={{color: 'red', fontSize: 35}}/>
        </button>
        :
        <button onClick={() => toggleTerms()}>
          <ExpandMore style={{color: 'red', fontSize: 35}}/>
        </button>   
        }
      </div>
      <CSSTransition
        in={termsVisible}
        timeout={300}
        classNames="alert"
        unmountOnExit
      >
        <TermsNConditions/>
      </CSSTransition>      
      <div className="change-password">
        <div className="changePassTitle">
          <InfoIcon style={{color: Colors.primary, fontSize: 30}}/>
          <h4 style={{color: Colors.primary}}>{t("common:about-us")}</h4>
        </div>
        {aboutVisible ? 
        <button onClick={() => toggleAbout()}>
          <CancelPresentationIcon style={{color: 'red', fontSize: 35}}/>
        </button>
        :
        <button onClick={() => toggleAbout()}>
          <ExpandMore style={{color: 'red', fontSize: 35}}/>
        </button>   
        }
      </div>
      <CSSTransition
        in={aboutVisible}
        timeout={300}
        classNames="alert"
        unmountOnExit
      >
        <About/>
      </CSSTransition>
      <div className="change-password">
        <div className="changePassTitle">
          <ExitToAppIcon style={{color: Colors.primary, fontSize: 30}}/>
          <h4 style={{color: Colors.primary}}>{t("common:logout")}</h4>
        </div>
        {logoutVisible ? 
        <button onClick={() => toggleLogout()}>
          <CancelPresentationIcon style={{color: 'red', fontSize: 35}}/>
        </button>
        :
        <button onClick={() => toggleLogout()}>
          <ExpandMore style={{color: 'red', fontSize: 35}}/>
        </button>   
        }
      </div>
      <CSSTransition
        in={logoutVisible}
        timeout={300}
        classNames="alert"
        unmountOnExit
      >
        <LogoutComponent            
          onClickNo={toggleLogout}
          deleteItem={logout}
          loading={loading}
        />
      </CSSTransition>
    </div>  
  )
}

export default inject('feathersStore')(observer(Settings));