import React from 'react'
import Avatar from '@material-ui/core/Avatar';
import Rating from '@material-ui/lab/Rating';


const RatedCard = ({username, date, value, comment})=> {

    return(
        <div className="rated-card">
            <div className="rated-card-title">
                <Avatar/>
                <p style={{marginLeft: 10, marginTop: 5}}>{username}</p>
                <p style={{marginLeft: 5, marginTop: 5, color: 'grey'}}>- {date}</p>
                <Rating style={{marginLeft: 20, marginTop: 5}} name="read-only" value={value} readOnly />
            </div>
            {comment != null && <div className="rated-card-commentText">
                <p>"{comment}"</p>
            </div>}
        </div>
    )
}

export default RatedCard;