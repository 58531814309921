import React from 'react'
import Rating from '@material-ui/lab/Rating';
import Avatar from '@material-ui/core/Avatar';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

const RatingForm = ({value, onChange})=> {

    return(
        <div className="product-evaluation-body">
            <Avatar/>
            <p>User Name</p>
            <Rating
            name="simple-controlled"
            value={value}
            onChange={onChange}
            />
            <TextareaAutosize style={{paddingLeft: 5}} aria-label="minimum height" rowsMin={3} placeholder="Γράψτε ένα σχόλιο.." />
            <button className="btn btn-primary btn-sm" type="submit" >Προσθήκη αξιολόγησης</button>
        </div>
    )
}

export default RatingForm;