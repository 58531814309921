import React, {useState, useEffect} from 'react'
//import TopBar from '../../components/screenComponents/topBar/TopBar';
import TopBar from '../../components/screenComponents/topBarBootstrap/TopBarBootstrap';
import LogoArea from '../../components/screenComponents/logoArea'
import NavigationBar from '../../components/screenComponents/navigationBar'
import Body from '../../components/screenComponents/body'
import Footer from '../../components/screenComponents/footer/Footer'
import RegistrationCard from '../../components/modals/registrationCard'
import Prompt from '../../components/modals/prompt'
import ResetPassword from '../../components/modals/resetPassword'
import VerificationCode from '../../components/modals/verificationCode'
import ConfirmExitModal from '../../components/modals/confirmExitModal'
import OverStockWarning from '../../components/modals/overStockWarning'
import { inject, observer } from "mobx-react";
import "./Home.css";

const Home = ({feathersStore})=> {
  
  const status = localStorage.getItem("LogedIn")
  const LogedIn = JSON.parse(status)

  const OverStock = localStorage.getItem("Warning")
  const ErrorWarning = JSON.parse(OverStock)

  const [warning, setWarning] = useState(ErrorWarning)
  useEffect(()=> {
      setWarning(ErrorWarning)
  },[OverStock])

  useEffect(() => {
    const cart = localStorage.getItem('fdwa_cart');
    cart && feathersStore
      .setCartLength(JSON.parse(localStorage.getItem('fdwa_cart')).length);
  }, [])

  const [visible, setModal] = useState(false);
  const [visibleRegister, setRegistrationModal] = useState(false);
  const [visibleVerification, setVisibleVerification] = useState(false);
  const [payload, setPayload] = useState({});  
  const [resetPasswordModal, setResetPasswordModal] = useState(false)
  const [exit, setExit] = useState(false);

  const handleModal = ()=> {
      if(LogedIn === null || LogedIn === false){
          setModal(!visible);
      }else{
          setExit(true)
      }      
  }

  const openRegistrationModal = ()=> {
      setModal(false);
      setRegistrationModal(true);
  }

  const closeRegistrationModal = ()=> {
      setRegistrationModal(false);
  }

  const SubmitReset = ()=> {
      setResetPasswordModal(!resetPasswordModal);
  }

  const facebookLogin = ()=> {
      setModal(false);
      localStorage.setItem("LogedIn", true)
      localStorage.setItem("Role", "User")
  }

  const openVerification = pload => {
      setRegistrationModal(false);
      setVisibleVerification(true);
      setPayload(pload);
    }
    
    const closeVerification = ()=> {
      setVisibleVerification(false);
      localStorage.setItem("LogedIn", true)
      localStorage.setItem("Role", "User")
    }

    const Logout = ()=> {
      localStorage.setItem("LogedIn", false)
      setExit(!exit);
      window.location.reload(false);
  }

  const NoLogout = ()=> {
      setExit(!exit);
  }

  const closeWarning = ()=> {
      localStorage.setItem("Warning", false)
      setWarning(false)
  }

  const clickEnter = ()=> {
      localStorage.setItem("Role", "Admin")
      localStorage.setItem("LogedIn", true)
      setModal(false)
  }
  
  return(
    
    <div className="App">
      <Prompt 
        visible={visible}
        onClickForgot={SubmitReset} 
        close={handleModal}
        onClickEnter={clickEnter}
        onClickAway={handleModal}
        onClickRegister={openRegistrationModal}
        onClickFacebook={facebookLogin}
        />
      <RegistrationCard
        visible={visibleRegister} 
        close={closeRegistrationModal}
        onClickAway={closeRegistrationModal}
        onClick={openVerification}
        />
        <ResetPassword
        visible={resetPasswordModal}
        onClickSubmit={SubmitReset}
        close={SubmitReset}
        />
        <VerificationCode
          visible={visibleVerification}
          onClickCloseCode={closeVerification}
          onClickAway={closeVerification}
          payload={payload}
        />
        <ConfirmExitModal
          visible={exit}
          no={NoLogout}
          yes={Logout}
          />
        <OverStockWarning 
          visible={warning}
          onClick={closeWarning}
        />
      <TopBar onClickSignIn={()=> handleModal()}/>   
      <Body/> 
      <Footer/>
    </div>
   
  )
}

export default inject('feathersStore')(observer(Home));