import React, { useEffect } from 'react'
import Phone from '@material-ui/icons/Phone'
import Email from '@material-ui/icons/MailOutline'
import HomeWork from '@material-ui/icons/HomeWork';
import "./Footer.css";
import { useTranslation } from 'react-i18next';
import { inject, observer } from "mobx-react";
import {Link} from 'react-router-dom';

const Footer = ({ feathersStore })=> {

  const { t, i18n } = useTranslation('footer');

  useEffect(()=> {
    i18n.changeLanguage(feathersStore.language);    
  },[feathersStore.language]);

  return(
    <div className="footer">
      <div className="site-information">
        <h3>{t("footer:contact-hours")}:</h3>
        <p>{t("footer:week")}: 09:30 – 17:30</p>
        <h4>{t("footer:contact-us")}:</h4>
        <a href="tel:2114115591">
          <p><Phone/>   
            :&nbsp;{feathersStore.isAuthenticated && 
              feathersStore.settings?.phoneNumbers &&
              feathersStore.settings?.phoneNumbers.find(p => p.numberEnabled).phoneNumber}
          </p>
        </a>
        <p><Email/> : appdate.gr@gmail.com</p>
        <p><HomeWork/> : {t("footer:address")}</p>
        <h5>{t("footer:find-us")} <a href="https://www.google.com/maps/place/%CE%9C%CE%B5%CF%83%CE%BF%CE%B3%CE%B5%CE%AF%CF%89%CE%BD+131,+%CE%9C%CE%B1%CF%81%CE%BF%CF%8D%CF%83%CE%B9+151+26/@38.0407648,23.817371,17z/data=!3m1!4b1!4m5!3m4!1s0x14a199245916ff01:0x37c67e9d27e6d085!8m2!3d38.0407648!4d23.8195597" target="_blank">{t("footer:map")}</a>.</h5>
        <div className="row-flex-center"> 
          <Link className="privacy-text mr-4" to="/privacypolicy">{t("footer:privacy-policy")}</Link>
          <Link className="privacy-text" to="/termsnconditions">{t("footer:terms-of-service")}</Link>
        </div> 
      </div>
      <div className="copyright">
        <p style={{color: '#fff'}}>POWERED BY <a href="https://appdate.gr" target="_blank">APPDATE.GR</a></p>
      </div>
    </div>
  )
}

export default inject('feathersStore')(observer(Footer));