import React from 'react'
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import {Link} from 'react-router-dom'

const AccountButton = ()=> {

    return(
        <Link to="/account">
            <button className="nav-button-SignIn">
                <PermContactCalendarIcon style={{fontSize: 30, color: '#fff'}}/>
                <span>myAccount</span>
            </button>
        </Link>
    )
}

export default AccountButton;