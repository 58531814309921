import React from 'react'
import ReactPaginate from 'react-paginate';
import ArrowLeft from '@material-ui/icons/ArrowLeft'
import ArrowRight from '@material-ui/icons/ArrowRight';
import "./Pagination.css";

const Pagination = ({pageCount, onPageChange})=> {

  return(
    <ReactPaginate
      previousLabel={<ArrowLeft/>}
      nextLabel={<ArrowRight/>}
      breakLabel={'...'}
      breakClassName={'break-me'}
      pageCount={pageCount}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={onPageChange}
      containerClassName={'pagination'}
      subContainerClassName={'pages pagination'}
      activeClassName={'active'}
    />
  )
}

export default Pagination;