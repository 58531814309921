import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import GreenSubmitButton from '../buttons/greenSubmitButton';
import { inject, observer } from "mobx-react";

function getModalStyle() {
  const top = 50 ;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));


const WishItemDeleteModal = ({visible, onClickNo, deleteItem, loading})=> {

  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  
    
    return (     
         <Dialog
          open={visible}
          onClose={onClickNo}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          disableRestoreFocus={true}
        >
           <DialogTitle id="alert-dialog-title">{"ΠΡΟΣΟΧΗ"}</DialogTitle>
           <DialogContent>
           <DialogContentText id="alert-dialog-description">
           Είστε σίγουροι θέλετε να αφαιρέσετε αυτό το προϊόν;
          </DialogContentText>
        </DialogContent>
        <DialogActions>
            <GreenSubmitButton 
              title={'ΟΧΙ'}
              loading={false}
              disabled={false} 
              onClick={onClickNo} 
              color="primary"   
              autoFocus          
            />   
            <h3 style={{color: 'grey'}}>|</h3>
            <GreenSubmitButton 
              title={'NAI'}
              loading={loading}
              disabled={false} 
              onClick={deleteItem}  
            />              
         </DialogActions>         
        </Dialog>     
    );
}

export default inject('feathersStore')(observer(WishItemDeleteModal));