import React,{ useState, useEffect } from 'react';
import Colors from '../assets/colors';
import { Link } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { inject, observer } from "mobx-react";
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import { useHistory } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: 350,
    flexGrow: 1,
    maxWidth: 450,    
  },
  button: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(0),
   // marginRight: theme.spacing(0),
    width: theme.spacing(8),
   // backgroundColor: Colors.primary,
    color: "#fff"  
  },
  menuItem: {
    fontSize: theme.spacing(2),
  }
}));

const NavigationBar = ({feathersStore})=> {

  const classes = useStyles(); 
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);  
  const [searchText, setSearchText] = useState("");
  const [buttonTitle, setButtonTitle] = useState("All");

  useEffect(() => {   
    setSearchText(feathersStore.searchCategory.searchText || "");
    setButtonTitle(feathersStore.searchCategory.title || "All");    
  }, [feathersStore?.searchCategory]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePress = item => () => { 
    setSearchText("");    
    const payload =  {    
      categoryId: item.id,  
      title: item.name, 
      searchText: ""
    }; 
    feathersStore.setSearchCategory(payload); 
    setButtonTitle(item.name);
    history.push({
      pathname: "/"  
    });
    handleClose();   
  }

  const searchChange = event => {
    setSearchText(event.target.value);
  }

  const searchClicked =  () => {
    const payload =  {    
      categoryId: feathersStore.searchCategory?.categoryId,  
      title: feathersStore.searchCategory?.title, 
      searchText: searchText
    }; 
    feathersStore.setSearchCategory(payload); 
    history.push({
      pathname: "/"    
    });
  }

  const renderTree = (nodes) => (
    <TreeItem 
      key={nodes.id} 
      nodeId={nodes.id.toString()} 
      label={nodes.name} 
      onLabelClick={handlePress(nodes)}
      collapseIcon={<ExpandMoreIcon />}
      expandIcon={<ChevronRightIcon />}      
    >
      {Array.isArray(nodes.childrens) ? nodes.childrens.map((node) => renderTree(node)) : null}
    </TreeItem>
  );
 
  return(
    <>
     
        <div className="dropdown">
          
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <TreeView
              className={classes.root}            
              defaultExpanded={[]}            
            >
              {feathersStore.categories ? 
              feathersStore.categories?.map(renderTree)  
              //  feathersStore.categories?.map(renderCategoryItem)
              :
              <CircularProgress 
                color="secondary" 
                style={{ margin: 6 }} 
                size={'4rem'}            
              />
              }
            </TreeView>
          </Menu>
        </div> 
        <div className="input-group" >
          <div className ="input-group-prepend">
            <button 
              className="btn btn-outline-secondary bg-secondary text-light" 
              type="button"
              onClick={handleClick}
            ><span>{buttonTitle}<span>{<ArrowDropDownIcon />}</span></span>
            </button>
          </div>    
          <input 
           
            className="form-control" 
            type="text" 
            placeholder="Search" 
            aria-label="Search"
            value={searchText}
            onChange={searchChange}
          />
          <div className="input-group-append">
            <button 
              className="btn btn-outline-secondary bg-warning text-light" 
              type="button"
              onClick={() => searchClicked()}>
                <span>{<SearchIcon />}</span>
            </button>
          </div> 
        </div>
    </>
  )
}

export default inject('feathersStore')(observer(NavigationBar));