import React, {useState, useEffect} from 'react';
import PageTitle from '../../components/screenComponents/pageTitle'
import Colors from '../../components/assets/colors'
import AccountBox from '@material-ui/icons/AccountBox'
import HistoryIcon from '@material-ui/icons/History';
import SettingsIcon from '@material-ui/icons/Settings';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ProfileSettings from '../../components/screenComponents/profileSettings'
import History from '../../components/screenComponents/history'
import Settings from '../../components/screenComponents/settings';
import Address from '../../components/screenComponents/address';
import "./Account.css";
import { useTranslation } from 'react-i18next';
import { inject, observer } from "mobx-react";

const Account = ({ feathersStore })=> {

  const { t, i18n } = useTranslation('account');

  const [profile, setProfile] = useState(true)
  const [history, setHistory] = useState(false) 
  const [settings, setSettings] = useState(false) 
  const [visibleAddress, setVisibleAddress] = useState(false);

  useEffect(()=> {
    i18n.changeLanguage(feathersStore.language);    
  },[feathersStore.language]);

  const profileClicked = ()=> {
    setProfile(true)
    setHistory(false)   
    setSettings(false)
    setVisibleAddress(false)
  }

  const historyClicked = ()=> {
    setProfile(false)
    setHistory(true)  
    setSettings(false)
    setVisibleAddress(false)
  }    

  const settingsClicked = ()=> {
    setProfile(false)
    setHistory(false) 
    setSettings(true)
    setVisibleAddress(false)
  }

  const addressesClicked = ()=> {
    setProfile(false)
    setHistory(false)    
    setSettings(false)
    setVisibleAddress(true);
  }    

 

  const handleMenuSelection = ()=> {

      if(profile) {
      
          return <ProfileSettings />

      }else if(history){

          return <History/>
    
      
    }else if(settings){

      return <Settings/>

    }else if(visibleAddress){
      return(
      <Address 
        
      />
      )
    }
  }

  return(
    <>
      <div className="container">
        <div className="cart-body" style={{minHeight: '5vh'}}>
          <PageTitle title={t("account:myAccount")}/>
        </div>  
      </div>
        <div className="account-board">
          <div className="account-board-header">
            <div className="title">
              <button onClick={profileClicked}>
                <AccountBox style={{color: profile && Colors.primary, fontSize: 32}}/>
                <h4 style={{color: profile && Colors.primary}}>{t("account:profile")}</h4>
              </button>
            </div>
            <div className="title">
              <button onClick={addressesClicked}>
                <LocationOnIcon style={{color: visibleAddress && Colors.primary, fontSize: 32}}/>
                <h4 style={{color: visibleAddress && Colors.primary}}>{t("account:addresses")}</h4>
              </button>
            </div>
            <div className="title">
              <button onClick={historyClicked}>
                <HistoryIcon style={{color: history && Colors.primary, fontSize: 32}}/>
                <h4 style={{color: history && Colors.primary}}>{t("account:history")}</h4>
              </button>  
            </div>         
            <div className="title">
              <button onClick={settingsClicked}>
                <SettingsIcon style={{color: settings && Colors.primary, fontSize: 32}}/>
                <h4 style={{color: settings && Colors.primary}}>{t("account:settings")}</h4>
              </button>
            </div>
          </div>
          <div className="account-board-body">
            {handleMenuSelection()}
          </div>
        </div>     
    </>
  )
}

export default inject('feathersStore')(observer(Account));