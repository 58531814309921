import React from 'react';
import Modal from 'react-awesome-modal';
import Button from '@material-ui/core/Button';
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';
import AppColors from '../assets/colors'


const ConfirmationModal = (props)=> {
    const useStyles = makeStyles((theme) => ({
        margin: {
          margin: theme.spacing(1),
        },
      }));
      
      const theme = createMuiTheme({
        palette: {
          primary: red,
          secondary: green,
        },
      });
    const classes = useStyles();
    
    return (
      <section>
        <Modal visible={props.visible} width="350" height="150" effect="fadeInUp" >
          <div className="modalContainer">
            <div className="modalTitleContainer">
              <p style={{color: AppColors.secondary}}>Είστε σίγουροι θέλετε να αφαιρεθεί το προϊόν<br/>
              <a style={{color: '#000'}}>{props.product}</a><br/>
              από το καλάθι;</p>
            </div>
            <div className="confirmationModalButtonsView">
              <Button 
                style={{color: AppColors.red, fontSize: 18}} 
                onClick={props.onClickNo}
              >ΟΧΙ</Button>
              <h3 style={{color: AppColors.secondary, marginTop: 10}}>|</h3>
              <Button 
                style={{color: AppColors.primary, fontSize: 18}} 
                onClick={props.onClickYes}
              >NAI</Button>
            </div>
          </div>
        </Modal>
      </section>
    );
}

export default ConfirmationModal;