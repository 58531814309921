import React from 'react'
import ShoppingCart from '@material-ui/icons/ShoppingCart'
import Colors from '../assets/colors'


const AddToCartSmall = ({ onClick })=> {


    return(
        <button type="button" className="btn shoppingCart-button" onClick={onClick}>
            <ShoppingCart style={{color: Colors.primary}}/>
        </button>
    )
}

export default AddToCartSmall;