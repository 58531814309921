import React from 'react';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import MenuItem from '@material-ui/core/MenuItem';
import ProductCard from '../cards/productCard/ProductCard';
import NavigateNext from '@material-ui/icons/NavigateNext'
import NavigateBefore from '@material-ui/icons/NavigateBefore'

const Popular = ({ handleAddToCart, getPrice, getOldPrice, getBadges, handlePress, popularProducts })=> {
  
  const getMainImage = (product)=> {
    let list = getImageArr(product);
    const mainImg = list.find(img => img.focused);  
    return mainImg;          
  }

  const getImageArr = product => {
    let imageArr = [];
    if(product.hasVariation){
      imageArr = product.variations
        .map(variation => variation.images
          .map(image => Object.assign(image, {variation: variation.name}))) //Add variation name to the image
        .flat();
    }else{
      imageArr = product.images              
    }  
    return imageArr;
  } 

  const bestSellersIterator = popularProducts.map(product => {
    
    return (
      <MenuItem key={product._id}>
        <ProductCard
          key={product._id}
          stock={product.handleStock && product.stock}
          image={getMainImage(product)}
          title={product.name}
          price={getPrice(product)}
          oldPrice={getOldPrice(product)}
          onClick={()=> handlePress(product)}
          onClickCart={() => handleAddToCart(product, true)}
          hasVariation={product.hasVariation}
          onClickForward={()=> handlePress(product)}
          badges={getBadges(product)}
        />
      </MenuItem>
    );
  });

  const ArrowLeft = <NavigateBefore style={{cursor: 'pointer', fontWeight: 'bold', fontSize: 50, color: 'grey'}}/>
  const ArrowRight = <NavigateNext style={{cursor: 'pointer', fontWeight: 'bold', fontSize: 50, color: 'grey'}}/>;

  return(
    <div className="container">
      <h4>Top Sellers</h4>
      <ScrollMenu
        data={bestSellersIterator}
        arrowLeft={ArrowLeft}
        arrowRight={ArrowRight}      
      />      
    </div>
  )


}

export default Popular;