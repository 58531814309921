import React from 'react'

const CheckoutComponent = ({title, quantity, total})=> {

    return(
        <div className="checkout-order-table-component">
            <p id="ch-tb-price">{title} x {quantity}</p>
            <p>{total}€</p>
        </div>
    )
}

export default CheckoutComponent;