import React from 'react';
import Colors from '../assets/colors'
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import TermsText from './termsText';

const TermsNConditions = ()=> {
    
  return(
    <div className="body">
   {/*   <CategoryPic image={back}/>    */}
      <div className="pageIcon">
        <FormatListNumberedIcon style={{fontSize: 150, color: Colors.primary}}/>
      </div>     
      <div className="categoryView">
        <TermsText/>
      </div> 
    </div>
  )
}

export default TermsNConditions;